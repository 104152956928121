import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../Common/Footer'
import Requestquote from '../../Common/Requestquote'

class ProjectDetails extends React.Component {
    render() {
        return (
            <>
                {/* <!-- Start Page Title Area --> */}
                <section className="page-title-area uk-page-title">
                    <div className="uk-container">
                        <h1>Publicité en Ligne</h1>
                        <ul>
                            <li>
                                <Link to="/">Marketing Digital</Link>
                            </li>
                            <li>Publicité en Ligne</li>
                        </ul>
                    </div>
                </section>
                {/* <!-- End Page Title Area --> */}

                {/* <!-- Start Project Details Area --> */}
                <section className="project-details-area uk-project-details uk-section">
                    <div className="uk-container">
                        <div className="uk-grid uk-flex project-details">
                            <div className="project-details-img uk-width-expand">
                                <img
                                    src="/assets/images/imgcm/DigitalBuyingmediaDigital.jpg"
                                    alt="project"
                                />
                            </div>

                            <div className="item uk-width-1-5">
                                <div className="project-details-info">
                                    <ul>
                                        {/* <li><span>Customer Name:</span> Steven John</li> */}
                                        <li>
                                            <span>Catégorie:</span> Marketing
                                            Digital
                                        </li>

                                        {/* <li><span>Tags:</span> <Link to="#">Art</Link>, <Link to="#">Design</Link></li> */}
                                        <li>
                                            <span>Partager:</span>

                                            <ul>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-logo"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-twitter"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-logo-1"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-linkedin"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="project-details-desc">
                            <h3>Publicité en Ligne</h3>
                            <h5>
                                La publicité en ligne sera entièrement
                                automatisée à l’avenir. Nous développons nos
                                propres outils et relions toutes les plateformes
                                publicitaires, ce qui est à la base de nos
                                campagnes display, native et social.
                            </h5>
                            <h5>
                                Nous réalisons de la publicité programmatique
                                basée sur le coût et nous déterminons ensemble
                                l’objectif de vos campagnes pour des
                                performances grandissantes.
                            </h5>
                            <h5>
                                Grâce à notre réseau de partenaires médias
                                premium, nous nous adressons à votre cible en
                                nous basant sur des données de profil très
                                précises.
                            </h5>
                            <h5>
                                Notre technologie nous permet de maîtriser
                                l’ensemble des formats standards définis par
                                l’IAB, nous réalisons aussi des formats
                                personnalisés pour obtenir des positions fortes
                                auprès des éditeurs.
                            </h5>
                        </div>

                        <Requestquote />
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}

export default ProjectDetails
