import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../Common/Footer'
import Requestquote from '../../Common/Requestquote'

class ProjectDetails extends React.Component {
    render() {
        return (
            <>
                {/* <!-- Start Page Title Area --> */}
                <section className="page-title-area uk-page-title">
                    <div className="uk-container">
                        <h1>Cinéma </h1>
                        <ul>
                            <li>
                                <Link to="/">Media</Link>
                            </li>
                            <li>Cinéma </li>
                        </ul>
                    </div>
                </section>
                {/* <!-- End Page Title Area --> */}

                {/* <!-- Start Project Details Area --> */}
                <section className="project-details-area uk-project-details uk-section">
                    <div className="uk-container">
                        <div className="uk-grid uk-flex project-details">
                            <div className="project-details-img uk-width-expand">
                                <img
                                    src="/assets/images/imgcm/cinema.jpg"
                                    alt="project"
                                />
                            </div>

                            <div className="item uk-width-1-5">
                                <div className="project-details-info">
                                    <ul>
                                        {/* <li><span>Customer Name:</span> Steven John</li> */}
                                        <li>
                                            <span>Catégorie:</span> Media
                                        </li>

                                        {/* <li><span>Tags:</span> <Link to="#">Art</Link>, <Link to="#">Design</Link></li> */}
                                        <li>
                                            <span>Partager:</span>

                                            <ul>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-logo"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-twitter"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-logo-1"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-linkedin"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="project-details-desc">
                            <h3>Cinéma </h3>
                            <h5>
                                La publicité sur les écrans de Cinéma est un
                                excellent moyen de faire passer votre message
                                devant un public captif, détendu et concentré.
                            </h5>
                            <h5>
                                La publicité dans les salles de cinéma vous
                                permet d'afficher votre annonce en grand, à un
                                public exclusif !
                            </h5>
                        </div>

                        <Requestquote />
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}

export default ProjectDetails
