import React from 'react'
import ReactDOM from 'react-dom'

let lax = null
if (typeof document !== 'undefined') {
    lax = require('lax.js')
}

class LaxButton extends React.Component {
    componentDidMount() {
        this.el = ReactDOM.findDOMNode(this)
        if (lax) lax.addElement(this.el)
    }

    componentWillUnmount() {
        if (lax) lax.removeElement(this.el)
    }

    render() {
        return (
            <a
                href={`/contact?referral=${
                    window ? window.location.pathname : ''
                }`}
                className="uk-button uk-button-default lax"
                data-lax-preset="driftLeft"
            >
                Contactez nos experts <i className="flaticon-right"></i>
            </a>
        )
    }
}

export default LaxButton
