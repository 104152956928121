import React from 'react'
import { Link } from 'react-router-dom'
import GoTop from './GoTop'

class Footer extends React.Component {
    render() {
        return (
            <footer className="footer-area uk-dark uk-footer">
                <div className="uk-container">
                    <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
                        <div className="item">
                            <div className="single-footer-widget">
                                <div className="logo">
                                    <Link to="/">
                                        <img
                                            src="/assets/images/logo.png"
                                            alt="logo"
                                        />
                                    </Link>
                                </div>
                                <div className="location">
                                    <p>
                                        7 Bis, Rue Asaad Ibnou Zarara
                                        <br />
                                        Rés. Tarik B - Maarif <br />
                                        Casablanca - Maroc <br />
                                        Tél :+212 05 22 48 72 72 <br />
                                        Fax :+212 05 22 47 13 15
                                    </p>
                                </div>
                                <ul className="social">
                                    <li>
                                        <a
                                            href="https://www.facebook.com/N7-Com-Group-205164366166817/"
                                            target="_blank"
                                        >
                                            <i className="flaticon-logo"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a href="https://www.instagram.com/groupn7/?igshid=1448kyzokefcp">
                                            <i className="flaticon-instagram"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://twitter.com/GroupeN7"
                                            target="_blank"
                                        >
                                            <i className="flaticon-twitter"></i>
                                        </a>
                                    </li>
                                    <li>
                                        <a
                                            href="https://www.linkedin.com/company/n7-com-group/?viewAsMember=true"
                                            target="_blank"
                                        >
                                            <i className="flaticon-linkedin"></i>
                                        </a>
                                    </li>
                                </ul>
                            </div>
                        </div>

                        <div className="item">
                            <div className="single-footer-widget ">
                                <h3>Nous contacter</h3>
                                <div className="bar"></div>
                                <div className="location">
                                    <p>
                                        E-mail :{' '}
                                        <a
                                            href="mailto:contact@n7.ma"
                                            target="_top"
                                            style={{ display: 'inline' }}
                                        >
                                            {' '}
                                            contact@n7.ma
                                        </a>
                                    </p>
                                    <a
                                        href={`/contact?referral=${
                                            window
                                                ? window.location.pathname
                                                : ''
                                        }`}
                                        className="uk-button uk-button-default"
                                    >
                                        Consultez nos experts
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>

                    <div className="copyright-area">
                        <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-1@m uk-child-width-1-2@s">
                            <div className="item">
                                <p>
                                    Copyright © 2019 N7 Communication Group -
                                    All rights reserved.
                                </p>
                            </div>
                        </div>
                        <GoTop scrollStepInPx="50" delayInMs="16.66" />
                    </div>
                </div>

                <div className="br-line"></div>
                <div className="footer-shape1">
                    <img src="/assets/images/footer-shape1.png" alt="shape" />
                </div>
                <div className="footer-shape2">
                    <img src="/assets/images/footer-shape2.png" alt="shape" />
                </div>
            </footer>
        )
    }
}

export default Footer
