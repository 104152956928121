import React from 'react'
import { withRouter } from 'react-router-dom'
import DefaultNavigation from '../Navigation/DefaultNavigation'
import NavigationThree from '../Navigation/NavigationThree'
import { ApiGetRouteMeta } from '../../tools/ApiServices'
import { truncaText, stripHtml } from '../../tools/Helpers'
import { Helmet } from 'react-helmet'

class NavBar extends React.Component {
    constructor(props) {
        super(props)
        this.state = { page: {} }
    }

    componentDidMount() {
        ApiGetRouteMeta(this.props.location.pathname).then(data => {
            this.setState({ page: data })
        })
    }

    render() {
        let { page } = this.state
        let description = page.description
            ? truncaText(stripHtml(page.description))
            : page.description
        return (
            <>
                <Helmet>
                    <meta charSet="utf-8" />
                    <title>{page.title}</title>
                    <meta name="description" content={description} />
                    <meta property="og:title" content={page.title} />
                    <meta property="og:description" content={description} />
                    <meta
                        property="og:image"
                        content={`${process.env.REACT_APP_API_URL}${
                            page.image ? page.image.url : '-'
                        }`}
                    />
                    <meta name="keywords" content={page.keywords} />
                </Helmet>
                {this.renderNavBar()}
            </>
        )
    }

    renderNavBar() {
        switch (this.props.location.pathname) {
            case '/contact':
                return <DefaultNavigation />
            case '/project-details':
                return <DefaultNavigation />
            case '/home-three':
                return <NavigationThree />
            default:
                return <DefaultNavigation />
        }
    }
}

export default withRouter(NavBar)
