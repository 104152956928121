import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../Common/Footer'
import Requestquote from '../../Common/Requestquote'

class ProjectDetails extends React.Component {
    render() {
        return (
            <>
                {/* <!-- Start Page Title Area --> */}
                <section className="page-title-area uk-page-title">
                    <div className="uk-container">
                        <h1>Web Design </h1>
                        <ul>
                            <li>
                                <Link to="/">Communication</Link>
                            </li>
                            <li>Web Design </li>
                        </ul>
                    </div>
                </section>
                {/* <!-- End Page Title Area --> */}

                {/* <!-- Start Project Details Area --> */}
                <section className="project-details-area uk-project-details uk-section">
                    <div className="uk-container">
                        <div className="uk-grid uk-flex project-details">
                            <div className="project-details-img uk-width-expand">
                                <img
                                    src="/assets/images/imgcm/Web-Design.jpg"
                                    alt="project"
                                />
                            </div>

                            <div className="item uk-width-1-5">
                                <div className="project-details-info">
                                    <ul>
                                        <li>
                                            <span>Catégorie:</span>{' '}
                                            Communication
                                        </li>
                                        <li>
                                            <span>Partager:</span>

                                            <ul>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-logo"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-twitter"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-logo-1"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-linkedin"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="project-details-desc">
                            <h3>Web Design </h3>

                            <h5>
                                Entre élégance percutante et simplicité
                                sophistiquée, nous trouvons le juste équilibre
                                pour transmettre vos messages et réussir
                                l'engagement de vos cibles.
                            </h5>
                            <h5>
                                Nous concevons des sites Web performants qui
                                améliorent l'expérience utilisateur, quel que
                                soit l'écran choisi. Nous développons des
                                solutions créatives et à la pointe de la
                                technologie, qui assurent la croissance et le
                                succès de votre marque.
                            </h5>
                            <h5>
                                Ce qui compte pour vous, compte également pour
                                nous, contactez nos conseillers et créez votre
                                solution unique !
                            </h5>

                            {/* <blockquote className="blockquote">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis noexercit ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt Lorem ipsum dolor sit amet.</p>
                            </blockquote> */}

                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis noexercit ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p> */}
                        </div>

                        <Requestquote />
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}

export default ProjectDetails
