import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../Common/Footer'
import Requestquote from '../../Common/Requestquote'

class ProjectDetails extends React.Component {
    render() {
        return (
            <>
                {/* <!-- Start Page Title Area --> */}
                <section className="page-title-area uk-page-title">
                    <div className="uk-container">
                        <h1>Street Marketing </h1>
                        <ul>
                            <li>
                                <Link to="/">Communication</Link>
                            </li>
                            <li>Street Marketing </li>
                        </ul>
                    </div>
                </section>
                {/* <!-- End Page Title Area --> */}

                {/* <!-- Start Project Details Area --> */}
                <section className="project-details-area uk-project-details uk-section">
                    <div className="uk-container">
                        <div className="uk-grid uk-flex project-details">
                            <div className="project-details-img uk-width-expand">
                                <img
                                    src="/assets/images/imgcm/DigitalMarketing.jpg"
                                    alt="project"
                                />
                            </div>

                            <div className="item uk-width-1-5">
                                <div className="project-details-info">
                                    <ul>
                                        {/* <li><span>Customer Name:</span> Steven John</li> */}
                                        <li>
                                            <span>Catégorie:</span>{' '}
                                            Communication
                                        </li>

                                        {/* <li><span>Tags:</span> <Link to="#">Art</Link>, <Link to="#">Design</Link></li> */}
                                        <li>
                                            <span>Partager:</span>

                                            <ul>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-logo"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-twitter"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-logo-1"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-linkedin"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="project-details-desc">
                            <h3>Street Marketing </h3>
                            <h5>
                                Parce que les plus grandes marques sont souvent
                                les plus visibles. Et parce que nos annonceurs
                                ont tous le droit aux mêmes privilèges.
                            </h5>
                            <h5>
                                Que nous avons décidé d’investir les rues pour
                                les faire VIVRE…
                            </h5>
                            <h5>
                                Nous créons des événements festifs, nous
                                planifions la rencontre de la marque avec ses
                                consommateurs à travers des animations et des
                                concepts inédits qui marquent les esprits et qui
                                s’inscrivent dans le Top of Mind.
                            </h5>
                            <h5>
                                Distribution de Flyers, Walkers, segways, hommes
                                ballons, camions publicitaires, Electro cycles ,
                                autant d’outils qui permettent de créer le BUZZ
                                offline et le diffuser Online !
                            </h5>
                        </div>

                        <Requestquote />
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}

export default ProjectDetails
