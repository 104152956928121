import React from 'react'
import ReactModal from 'react-modal'
import VideoCover from 'react-video-cover'

class Banner extends React.Component {
    state = {
        isOpen: false
    }

    openModal = () => {
        this.setState({ isOpen: true })
    }

    render() {
        let { modalText, modalTitre } = this.state
        return (
            <div id="home">
                <ReactModal
                    isOpen={this.state.showModal}
                    contentLabel="Modal #1 Global Style Override Example"
                    onRequestClose={this.handleCloseModal}
                    className="services-modal-box"
                >
                    <button
                        onClick={this.handleCloseModal}
                        className="modal-close-btn"
                    >
                        <i className="flaticon-close"></i>
                    </button>

                    <div className="box1">
                        <h2>{modalTitre}</h2>
                        <p>
                            {modalText} <br />
                            <form class="uk-form">
                                <div class="uk-form-row">
                                    <input style={{ width: "100%", margin: "1rem 0", padding: "0.5rem 0" }} type="text" placeholder="Nom"></input>
                                </div>
                                <div class="uk-form-row">
                                    <input style={{ width: "100%", padding: "0.5rem 0" }} type="password" placeholder="Adresse Email"></input>
                                </div>
                                <br/>
                                <a href="#" className="uk-button uk-button-default">
                                    Soumettre
                                </a>
                            </form>
                        </p>
                    </div>
                </ReactModal>
                <div className="banner-video">
                    <div
                        style={{
                            overflow: 'hidden',
                            width: '100vw',
                            height: '90vh'
                        }}
                    >
                        <VideoCover
                            remeasureOnWindowResize={true}
                            videoOptions={{
                                src: '/assets/video/N7-Video.mp4',
                                autoPlay: true,
                                loop: true,
                                muted: true
                            }}
                        />
                    </div>
                    <div className="overlay">
                        <div className="text-content">
                            <div className="d-table">
                                <div className="d-table-cell">
                                    <div className="uk-container">
                                        <div className="main-banner-content">
                                            <h1 className="big-title">
                                                Groupe de Communication <br />{' '}
                                                Marocain
                                            </h1>
                                            <p className="big-title-p">
                                                Groupe de communication
                                                Marocain, regroupant une agence
                                                de conseils en communication,
                                                une agence de médias, ainsi
                                                qu’une agence spécialisée dans
                                                le marketing digital et la
                                                communication en ligne.
                                            </p>
                                            <a
                                                href={`/contact?referral=${
                                                    window
                                                        ? window.location
                                                              .pathname
                                                        : ''
                                                }`}
                                                className="uk-button uk-button-default"
                                            >
                                                Prendre contact
                                            </a>
                                            <a
                                                onClick={e =>
                                                    this.handleOpenModal(
                                                        "Plaquette de l'agence",
                                                        'Merci de nous laisser vos coordonnées avant de télécharger notre plaquette.'
                                                    )
                                                }
                                                className="video-btn popup-youtube"
                                            >
                                                <button className="rounded-button">
                                                    <img style={{ width: "100%" }} src="/assets/images/download.svg" />
                                                </button>{' '}
                                                Demandez votre plaquette
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }

    constructor() {
        super()

        this.state = {
            showModal: false,
            modalText: '',
            modalTitre: ''
        }

        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleDemand = this.handleDemand.bind(this)
    }

    toggleShowing(event, elementId) {
        event.target.style.display = 'none'
        let el = document.querySelector(`#${elementId}`)
        el.classList.toggle('show')
        el.classList.toggle('hide')
    }

    handleOpenModal(title, message) {
        let el = document.querySelector('#root')
        el.classList.add('back-blur')

        this.setState({
            showModal: true,
            modalTitre: title,
            modalText: message
        })
    }

    handleCloseModal() {
        let el = document.querySelector('#root')
        el.classList.remove('back-blur')
        this.setState({ showModal: false })
    }

    handleDemand() {
        this.handleCloseModal()
        this.props.history.push('/contact')
    }
}

export default Banner
