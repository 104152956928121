import fetch from './FetchConfig'

let apiUrl = process.env.REACT_APP_API_URL
let apiBase = `${apiUrl}`

export const ApiCountBlogs = () =>
    fetch({
        url: `${apiBase}/blogs/count`,
        method: 'GET'
    })

export const ApiGetBlogs = (page, limit, { keyword, categoryId }) => {
    let url = `${apiBase}/blogs?_sort=created_at:DESC&_start=${page * limit -
        limit}&_limit=${limit}`

    if (keyword && keyword != '') url = `${url}&content_contains=${keyword}`
    if (categoryId && categoryId != '') url = `${url}&catalogue=${categoryId}`

    return fetch({
        url: url,
        method: 'GET'
    })
}

export const ApiGetLastBlogs = () =>
    fetch({
        url: `${apiBase}/blogs?_sort=created_at:DESC&_limit=6`,
        method: 'GET'
    })

export const ApiGetBlog = slug =>
    fetch({
        url: `${apiBase}/blogs?slug=${slug}`,
        method: 'GET'
    })

export const ApiGetCategories = () =>
    fetch({
        url: `${apiBase}/catalogues?_sort=created_at:DESC&_limit=3`,
        method: 'GET'
    })

export const ApiGetRouteMeta = tag =>
    fetch({
        url: `${apiBase}/pages/getTag?tag=${tag}`,
        method: 'GET'
    })

export const ApiAddContact = data =>
    fetch({
        url: `${apiBase}/contacts`,
        method: 'POST',
        data
    })

export const ApiAddMessage = (data, channel) =>
    fetch({
        url: `${apiBase}/messages/`,
        method: 'POST',
        data,
        headers: {
            'user-email': channel
        }
    })
