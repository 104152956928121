import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../Common/Footer'

class ProjectDetails extends React.Component {
    render() {
        return (
            <>
                {/* <!-- Start Page Title Area --> */}
                <section className="page-title-area uk-page-title">
                    <div className="uk-container">
                        <h1>Production Publicitaire</h1>
                        <ul>
                            <li>
                                <Link to="/">N7 Communication</Link>
                            </li>

                            <li>Production Publicitaire</li>
                        </ul>
                    </div>
                </section>
                {/* <!-- End Page Title Area --> */}

                {/* <!-- Start Project Details Area --> */}
                <section className="project-details-area uk-project-details uk-section">
                    <div className="uk-container">
                        <div className="uk-grid uk-flex project-details">
                            <div className="project-details-img uk-width-expand">
                                <img
                                    src="/assets/images/imgcm/ProductionPub.jpg"
                                    alt="project"
                                />
                            </div>

                            <div className="item uk-width-1-5">
                                <div className="project-details-info">
                                    <ul>
                                        {/* <li><span>Customer Name:</span> Steven John</li> */}
                                        <li>
                                            <span>Catégorie:</span> N7
                                            Communication
                                        </li>

                                        {/* <li><span>Tags:</span> <Link to="#">Art</Link>, <Link to="#">Design</Link></li> */}
                                        <li>
                                            <span>Partager:</span>

                                            <ul>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-logo"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-twitter"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-logo-1"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-linkedin"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="project-details-desc">
                            <h3>Production Publicitaire </h3>
                            <p>
                                A la recherche d’une production de film
                                institutionnel, d’un spot télévision, d’un spot
                                radio et d’une simple capsule digitale, ou bien
                                vous recherchez un partenaire fiable dans
                                l’industrie publicitaire, venez découvrir dans
                                nos ateliers les plus grands projets réalisés en
                                installation de panneaux d’affichage, de
                                palissades de chantiers, en affichage digital et
                                en production de véhicules publicitaires,
                            </p>
                            <p>
                                Faites vous accompagner par des pros, et
                                contactez notre équipe de production.
                            </p>
                            <p>
                                Nos concepts sont clé en main, de l’idée et à la
                                livraison, nous répondons à tous les budgets
                            </p>
                        </div>

                        <div className="project-next-and-prev">
                            <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s">
                                <div className="item">
                                    <h3>Demander un devis</h3>
                                    <div className="single-footer-widget ">
                                        <div className="bar"></div>
                                        <p>
                                            Discutons de la façon dont nous
                                            pouvons vous aider à vous démarquer.
                                        </p>
                                    </div>
                                </div>

                                <div className="item uk-text-right">
                                    <Link
                                        to={`/contact?referral=${
                                            window
                                                ? window.location.pathname
                                                : ''
                                        }`}
                                        className="uk-button uk-button-default"
                                    >
                                        Demander un devis
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}

export default ProjectDetails
