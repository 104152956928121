import React from 'react'
import { Link } from 'react-router-dom'

import Carousel from 'react-multi-carousel'
// import 'react-multi-carousel/lib/styles.css'

const options = {
    items: 4,
    loop: true,
    margin: 10,
    autoplay: true,
    autoplayTimeout: 1000,
    autoplayHoverPause: true,
    navText: [
        "<i className='flaticon-back'></i>",
        "<i className='flaticon-right'></i>"
    ],
    responsive: {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 6
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 6
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 3
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 2
        }
    }
}

class PartnerLogo extends React.Component {
    render() {
        return (
            <div className="partner-area uk-section uk-padding-remove-top">
                <div className="uk-container">
                    {/* <div className="partner-slides owl-carousel owl-theme"> */}
                    <Carousel
                        className="partner-slides owl-carousel owl-theme"
                        {...options}
                    >
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/i71.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>

                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/i76.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>

                        <div className="item">
                            <Link to="#">
                                <picture className="about-img1">
                                    <source srcSet="/assets/images/references/i81.webp" type="image/webp" />
                                    <source srcSet="/assets/images/references/i81.png" type="image/jpeg" />
                                    <img src="/assets/images/references/i81.png" alt="Partner" />
                                </picture>
                            </Link>
                        </div>

                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/l10.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>

                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/l14.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>

                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/l18.webp"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/l21.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/l3.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/l7.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/i72.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/i78.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/i82.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/l11.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/l15.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/l19.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/l22.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/l4.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/l8.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/i73.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/i79.webp"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/i85.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/l12.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/l16.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/l2.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/l23.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/l5.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/l9.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/i75.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/i80.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/l1.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/l13.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/l17.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/l20.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/l24.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                        <div className="item">
                            <Link to="#">
                                <img
                                    src="/assets/images/references/l6.png"
                                    alt="Partner"
                                />
                            </Link>
                        </div>
                    </Carousel>
                    {/* </div> */}
                </div>
            </div>
        )
    }
}

export default PartnerLogo
