import React from 'react'
import Footer from '../Common/Footer'

class Contact extends React.Component {
    render = () => {
        let { timer } = this.state
        return (
            <>
                {/* <!-- Start Page Title Area --> */}
                <section className="page-title-area uk-page-title">
                    <div className="uk-container">
                        {/* <h1>Merci, un conseillé prendra contact</h1> */}
                    </div>
                </section>
                {/* <!-- End Page Title Area --> */}

                {/* <!-- Start Project Details Area --> */}
                <section>
                    <div>
                        <div
                            style={{
                                padding: '6rem 0',
                                textAlign: 'center'
                            }}
                        >
                            <h2>
                                <span>
                                    Merci, un conseillé prendra contact
                                    <br />
                                    avec vous dans les plus brefs délais (
                                    {timer}:00)
                                </span>
                            </h2>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        )
    }

    constructor(props) {
        super(props)

        this.state = {
            timer: 10
        }
    }

    componentDidMount() {
        this.setTimer()
    }

    setTimer = () => {
        setInterval(() => {
            let { timer } = this.state
            this.setState({
                timer: timer - 1
            })
        }, 1000)

        setTimeout(() => {
            if (typeof window !== 'undefined') {
                window.location.href = '/'
            }
        }, 10000)
    }
}

export default Contact
