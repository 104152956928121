export default ({ url, method, data, headers }) =>
    fetch(url, {
        method,
        body: JSON.stringify(data),
        headers: {
            'content-type': 'application/json',
            accept: 'application/json',
            ...headers
        }
    }).then(response => response.json())
