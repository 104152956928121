import React, { Suspense } from 'react'
import { Switch, Route } from 'react-router-dom'
import NavBar from './components/Common/NavBar'
import Preloader from './components/Common/Preloader'
import HomeTwo from './components/Pages/HomeTwo'
import StrategieMarque from './components/Pages/comunication/StrategieMarque'
import CommunicationInfluence from './components/Pages/comunication/CommunicationInfluence'
import CreationArtistique from './components/Pages/comunication/CreationArtistique'
import StreetMarketing from './components/Pages/comunication/StreetMarketing'
import VideoProduction from './components/Pages/comunication/VideoProduction'
import EtudesEvaluations from './components/Pages/comunication/EtudesEvaluations'
import AchatEspacePubblicitaire from './components/Pages/comunication/AchatEspacePubblicitaire'
import ProductionPublicitaire from './components/Pages/comunication/ProductionPublicitaire'
import WebDesign from './components/Pages/comunication/WebDesign'
import AdverWeb from './components/Pages/Media/AdverWeb'
import BuyingMediaAffichage from './components/Pages/Media/BuyingMediaAffichage'
import PubliciteEnLigne from './components/Pages/Media/PubliciteEnLigne'
import DigitalPubliciteEnLigne from './components/Pages/Media/DigitalPubliciteEnLigne'
import SocialMedia from './components/Pages/Media/SocialMedia'
import StratégieMarketingDigital from './components/Pages/Media/StratégieMarketingDigital'
import Cinema from './components/Pages/Media/Cinema'
import Productionpublicitaireo from './components/Pages/comunication/Productionpublicitaireo'
import MediaRadio from './components/Pages/Media/MediaRadio'
import MediaTv from './components/Pages/Media/MediaTv'
import presse from './components/Pages/comunication/presse'

import { ApiAddMessage } from './tools/ApiServices'
import Thankyou from './components/Pages/Thankyou'
import BlogDetails from './components/Pages/BlogDetails'
import Blog from './components/Pages/Blog'
import Contact from './components/Pages/Contact'
import io from 'socket.io-client'
import 'react-chat-widget/lib/styles.css'

let addResponseMessage = () => {}
let addUserMessage = () => {}
let toggleWidget = () => {}
let Widget = null

if (typeof window !== 'undefined') {
    addUserMessage = require('react-chat-widget').addUserMessage
    addResponseMessage = require('react-chat-widget').addResponseMessage
    toggleWidget = require('react-chat-widget').toggleWidget
    Widget = require('react-chat-widget').Widget
}

class App extends React.Component {
    state = {
        loading: true,
        channel: null,
        socket: null
    }

    initOnesignal = () => {
        var OneSignal = window.OneSignal || []
        OneSignal.push(function() {
            OneSignal.init({
                appId: '0e2b587e-6e22-43e9-8f9b-182d0ea5fcde'
            })
        })
    }

    handleNewUserMessage = newMessage => {
        ApiAddMessage({ content: newMessage }, this.state.channel).then(
            data => {
                if (this.state.channel != data.email) {
                    this.setState({ channel: data.email })
                    window.localStorage.setItem('user-email', data.email)
                }
            }
        )
    }

    initChannel = () => {
        let socket = io.connect(process.env.REACT_APP_API_URL, {
            transports: ['websocket']
        })

        socket.on('connect', () => {
            socket.on(this.state.channel, function(msg) {
                if (msg.admin) addResponseMessage(msg.message)
            })
        })
    }

    setUpOldMessages() {
        let stringifyedMessages =
            window.localStorage.getItem('messages') || '[]'
        let messages = JSON.parse(stringifyedMessages)
        messages.map(el => addUserMessage(el))
    }

    componentDidMount() {
        setTimeout(() => {
            this.setState({ loading: false })
            this.initOnesignal()
        }, 1000)

        addResponseMessage('Quelle est votre question')

        let channel = window.localStorage.getItem('user-email')
        if (channel && channel !== 'undefined')
            this.setState({ channel }, this.initChannel)

        let opened = false
        window.onscroll = () => {
            let windowScrollHeight = window.innerHeight + window.scrollY
            let windowHeight = document.body.offsetHeight
            let exceptionalRoutes = ["/contact", "/thank-you"]
            let currentRoute = window.location.pathname
            if (windowScrollHeight >= windowHeight && !opened && !exceptionalRoutes.some(route => currentRoute.startsWith(route))) {
                opened = true
                toggleWidget()
            }
        }
    }

    render() {
        return (
            <>
                {this.state.loading ? <Preloader /> : ''}
                <div className="icon-bar">
                    <a
                        href="https://www.facebook.com/N7-Com-Group-205164366166817/"
                        rel="noopener noreferrer"
                        target="_blank"
                        className="facebook"
                    >
                        <i className="flaticon-logo"></i>
                    </a>

                    <a
                        href="https://instagram.com/groupn7?igshid=1448kyzokefcp"
                        rel="noopener noreferrer"
                        target="_blank"
                        className="instagram"
                    >
                        <i className="flaticon-instagram"></i>
                    </a>

                    <a
                        href="https://twitter.com/GroupeN7"
                        rel="noopener noreferrer"
                        target="_blank"
                        className="twitter"
                    >
                        <i className="flaticon-twitter"></i>
                    </a>

                    <a
                        href="https://www.linkedin.com/company/n7-com-group/?viewAsMember=true"
                        rel="noopener noreferrer"
                        target="_blank"
                        className="linkedin"
                    >
                        <i className="flaticon-linkedin"></i>
                    </a>
                </div>
                <NavBar />
                <Suspense fallback={<div>Loading...</div>}>
                    <Switch>
                        <Route path="/" exact component={HomeTwo} />
                        <Route
                            path="/Strategie-De-Marque"
                            exact
                            component={StrategieMarque}
                        />
                        <Route
                            path="/Creation-Artistique"
                            exact
                            component={CreationArtistique}
                        />
                        <Route
                            path="/Communication-d-influence"
                            exact
                            component={CommunicationInfluence}
                        />
                        <Route
                            path="/Achat-Espace-Publicitaire"
                            exact
                            component={AchatEspacePubblicitaire}
                        />
                        <Route
                            path="/Production-Publicitaire"
                            exact
                            component={ProductionPublicitaire}
                        />
                        <Route
                            path="/Street-Marketing"
                            exact
                            component={StreetMarketing}
                        />
                        <Route path="/Web-Design" exact component={WebDesign} />
                        <Route
                            path="/Video-Production"
                            exact
                            component={VideoProduction}
                        />
                        <Route
                            path="/Etudes-Evaluations"
                            exact
                            component={EtudesEvaluations}
                        />
                        <Route
                            path="/Buying-Media-Presse"
                            exact
                            component={presse}
                        />
                        <Route path="/AdverWeb" exact component={AdverWeb} />
                        <Route
                            path="/Buying-Media-Affichage"
                            exact
                            component={BuyingMediaAffichage}
                        />
                        <Route path="/Cinema" exact component={Cinema} />
                        <Route
                            path="/Publicite-En-Ligne"
                            exact
                            component={PubliciteEnLigne}
                        />
                        <Route
                            path="/Digital-Publicite-En-Ligne"
                            exact
                            component={DigitalPubliciteEnLigne}
                        />
                        <Route path="/Social-Media" exact component={SocialMedia} />
                        <Route
                            path="/Stratégie-Marketing-Digital"
                            exact
                            component={StratégieMarketingDigital}
                        />
                        <Route
                            path="/Production-publicitaire-o"
                            exact
                            component={Productionpublicitaireo}
                        />
                        <Route path="/Media-Radio" exact component={MediaRadio} />
                        <Route path="/Media-tv" exact component={MediaTv} />
                        <Route
                            path="/blog-details/:slug"
                            exact
                            component={BlogDetails}
                        />
                        <Route path="/thank-you/:slug" exact component={Thankyou} />
                        <Route path="/blog" exact component={Blog} />
                        <Route path="/contact" exact component={Contact} />
                    </Switch>
                </Suspense>
                {Widget ? (
                    <Widget
                        handleNewUserMessage={this.handleNewUserMessage}
                        profileAvatar="/assets/images/icon.png"
                        senderPlaceHolder="Tapez votre message"
                        title="BIENVENUE"
                        subtitle="Vous pouvez poser des questions ici"
                    />
                ) : (
                    <></>
                )}
            </>
        )
    }
}

export default App
