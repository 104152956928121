import React from 'react'
import { Link } from 'react-router-dom'
import { formatDate } from '../../tools/Helpers'
import { ApiGetLastBlogs } from '../../tools/ApiServices'

import Carousel from 'react-multi-carousel'
// import 'react-multi-carousel/lib/styles.css'

const options = {
    loop: true,
    nav: false,
    dots: true,
    smartSpeed: 2000,
    margin: 30,
    autoplayHoverPause: true,
    autoplay: true,
    responsive: {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 3
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    }
}

class LatestNews extends React.Component {
    render() {
        let { blogs } = this.state
        return (
            <section id="blog" className="blog-area  uk-blog uk-section">
                <div className="uk-container">
                    <div className="uk-section-title section-title">
                        <span>BLOG</span>
                        <h2>TENDANCES & ACTUS</h2>
                        <div className="bar"></div>

                        <Link
                            to="/blog"
                            className="uk-button uk-button-default"
                        >
                            Voir tout
                        </Link>
                    </div>

                    <Carousel
                        className="blog-slides owl-carousel owl-theme"
                        {...options}
                    >
                        {blogs.map((blog, index) => (
                            <div
                                key={`blog-${index}`}
                                className="single-blog-post"
                            >
                                <div className="blog-post-image">
                                    <Link to={`/blog-details/${blog.slug}`}>
                                        <div
                                            style={{
                                                height: '15rem',
                                                width: '100%',
                                                backgroundRepeat: 'no-repeat',
                                                backgroundSize: 'cover',
                                                backgroundImage: `url(${process.env.REACT_APP_API_URL}${blog.image.url})`
                                            }}
                                        />
                                    </Link>
                                </div>

                                <div className="blog-post-content">
                                    <span className="date">
                                        {formatDate(blog.created_at)}
                                    </span>
                                    <h3>
                                        <Link to={`/blog-details/${blog.slug}`}>
                                            {blog.title}
                                        </Link>
                                    </h3>
                                    <Link
                                        to={`/blog-details/${blog.slug}`}
                                        className="read-more"
                                    >
                                        Lire la suite
                                    </Link>
                                </div>
                            </div>
                        ))}
                    </Carousel>
                </div>
            </section>
        )
    }

    constructor(props) {
        super(props)
        this.state = { blogs: [] }
    }

    componentDidMount() {
        this.getLastBlogs()
    }

    getLastBlogs = () => {
        ApiGetLastBlogs().then(data => {
            this.setState({
                blogs: data
            })
        })
    }
}

export default LatestNews
