import React from 'react'
import Question from '../Common/Question'
import { ApiAddContact } from '../../tools/ApiServices'
import { slugify } from '../../tools/Helpers'
var $ = require('jquery')

let SweetAlert = null

if (typeof window !== 'undefined') {
    SweetAlert = require('sweetalert2-react').default
}

class Contact extends React.Component {
    render = () => {
        setTimeout(() => {
            if (typeof document !== 'undefined') this.checkQuestionVisibility()
        }, 0)
        return (
            <>
                {SweetAlert ? (
                    <SweetAlert
                        show={this.state.show}
                        toast={true}
                        title="votre demande est en cours de traitement"
                    />
                ) : (
                    <></>
                )}
                <form id="contact-form">
                    <div>{this.askMeSteps()}</div>
                </form>
            </>
        )
    }

    next = value => {
        this.setState({
            step: value
        })
    }

    askMeSteps = () => {
        let { step, questions, errors, submited, completed, timer } = this.state

        switch (step) {
            case 'questions':
                return (
                    <div className="main-section">
                        <div className="text-center welcome">
                            <div id={`question-0`} className="question" />
                            {questions.map((question, index) => (
                                <div
                                    key={index}
                                    onClick={e =>
                                        this.validatePosition(index + 1)
                                    }
                                >
                                    <div
                                        key={index}
                                        id={`question-${index + 1}`}
                                        className="question"
                                        onKeyDown={this.handleKeyPress}
                                    >
                                        <Question
                                            question={question.question}
                                            type={question.type}
                                            options={question.options}
                                            onChange={event =>
                                                this.changeInput(
                                                    question.name,
                                                    event
                                                )
                                            }
                                            component={Question}
                                            name={question.name}
                                        />
                                        <div className="error-message">
                                            {errors[question.name]}
                                        </div>
                                        <br />
                                        <button
                                            type="button"
                                            onClick={() => this.nextQuestion()}
                                            className="border-0 btn blue"
                                        >
                                            OK
                                        </button>
                                    </div>
                                </div>
                            ))}
                            <div
                                id={`question-${questions.length + 1}`}
                                className="question"
                            />
                        </div>
                    </div>
                )

            case 'finish':
                return (
                    <div className="main-section welcome">
                        {submited ? (
                            <>
                                <h2 className="succeeded">
                                    {completed ? (
                                        <span>
                                            Merci, un conseillé prendra contact
                                            <br />
                                            avec vous dans les plus brefs délais
                                            ({timer}:00)
                                        </span>
                                    ) : (
                                        <span>Chargement ...</span>
                                    )}
                                </h2>
                            </>
                        ) : (
                            <button onClick={this.submit} className="shinny">
                                Envoyer
                            </button>
                        )}
                    </div>
                )

            default:
                break
        }
    }

    constructor(props) {
        super(props)

        let questions = [
            {
                question: `
                    Nom complet *
                `,
                type: 'question',
                name: 'name',
                validate: ['require'],
                options: []
            },
            {
                question: `
                    Adresse e-mail  *
                    <small>Nous allons l'utiliser pour vous répondre. Pas de spam ou de newsletters inattendues.</small>
                `,
                type: 'question',
                name: 'email',
                validate: ['require', 'email'],
                options: []
            },
            {
                question: `
                    Quelle est la raison pour laquelle vous nous contactez?
                    <small>Pensez à cela comme à un champ d'objet dans un courrier électronique. Mais déjà rempli pour vous.</small>
                `,
                type: 'options',
                name: 'subject',
                validate: ['require'],
                options: [
                    "J'aimerais travailler avec vous",
                    "J'ai des feedback",
                    'Je cherche des conseils',
                    'Je voudrais juste discuter'
                ]
            },
            {
                question: `
                    Quel est votre message?
                `,
                type: 'question-text',
                name: 'message',
                validate: ['require'],
                options: []
            }
        ]

        this.state = {
            step: 'questions',
            questions: questions,
            show: false,
            mainQuestion: 1,
            submited: false,
            completed: false,
            form: {},
            errors: {}
        }
    }

    setTimer = () => {
        setInterval(() => {
            let { timer } = this.state
            this.setState({
                show: false,
                timer: timer - 1
            })
        }, 1000)

        setTimeout(() => {
            if (typeof window !== 'undefined') {
                window.location.href = '/'
            }
        }, 5000)
    }

    submit = () => {
        let { form } = this.state

        ApiAddContact(form).then(data => {
            // this.setTimer()
            this.setState({
                show: true
            })
            setTimeout(() => {
                if (window) {
                    const params = new URLSearchParams(window.location.search)
                    const referral = params.get('referral')
                    this.props.history.push(`/thank-you/${slugify(referral)}`)
                } else {
                    this.props.history.push(`/thank-you/`)
                }
            }, 500)
        })
    }

    handleKeyPress = target => {
        if (target.key === 'Enter' || target.key === 'Tab') this.nextQuestion()
    }

    validateEmail = (value) => {
        var input = document.createElement('input');
        input.type = 'email';
        input.required = true;
        input.value = value;
        return typeof input.checkValidity === 'function' ? input.checkValidity() : /\S+@\S+\.\S+/.test(value);
    }

    getAnswerValidMessage = (questionIndex) => {
        let { questions, form } = this.state
        let message = null
        return questions[questionIndex].validate.reduce((result, rule) => {
            let value = form[questions[questionIndex].name]
            if(rule == "require" && (!value || value == "")) message = "le champ est requis"
            if(rule == "email" && !this.validateEmail(value)) message = "l'adresse email est invalide"
            if(result != null && message == null) return result
            else return message
        }, null)
    }

    nextQuestion = (index = null) => {
        let { mainQuestion, questions } = this.state
        let validationMessage = this.getAnswerValidMessage(mainQuestion - 1)
        console.log("after validating", validationMessage)
        if(!validationMessage) {
            if (mainQuestion !== questions.length) {
                this.setState({
                    mainQuestion: index === null ? mainQuestion + 1 : index + 2
                })
            } else {
                this.setState({
                    step: 'finish',
                    submited: true,
                    timer: 5
                })
                this.submit()
            }

            this.handleInputFocuse()
        } else {
            alert(validationMessage)
        }
    }

    previousQuestion = () => {
        let { mainQuestion } = this.state

        if (mainQuestion > 0)
            this.setState({
                mainQuestion: mainQuestion - 1
            })

        this.handleInputFocuse()
    }

    handleInputFocuse = () => {
        setTimeout(function() {
            $('.question.active input').focus()
        }, 100)
    }

    checkQuestionVisibility = () => {
        let { mainQuestion } = this.state
        let $allQuestions = $('.question')
        let $mainQuestion = $(`#question-${mainQuestion}`)
        let $previousQuestion = $(`#question-${mainQuestion - 1}`)
        let $nextQuestion = $(`#question-${mainQuestion + 1}`)
        $(document).ready(function() {
            $allQuestions.removeClass('active').hide()
            $mainQuestion.show().addClass('active')
            $previousQuestion.show()
            $nextQuestion.show()
        })
    }

    validatePosition = questionIndex => {
        let { mainQuestion, questions } = this.state
        if (questionIndex > mainQuestion) {
            return this.nextQuestion(mainQuestion - 1)
        } else if (questionIndex < mainQuestion) {
            return this.previousQuestion()
        }
    }

    changeInput = (name, event) => {
        let { form } = this.state
        form[name] = event.target.value
        this.setState({
            form
        })
    }
}

export default Contact
