import '../../../node_modules/uikit/dist/css/uikit.min.css'
import '../../assets/css/style.scss'
import '../../assets/css/responsive.scss'
import React from 'react'
import { Link, withRouter } from 'react-router-dom'

class Navigation extends React.Component {
    render() {
        let { history } = this.props

        return (
            <>
                {/* Start Mobile Navbar */}
                <div
                    id="offcanvas-flip"
                    className="mobile-navbar uk-mobile-navbar"
                    uk-offcanvas="flip: true; overlay: true"
                >
                    <div className="uk-offcanvas-bar">
                        <button
                            className="uk-offcanvas-close"
                            type="button"
                            uk-close="true"
                        ></button>

                        <nav
                            className="uk-navbar-container"
                            data-uk-scrollspy-nav="offset: 0; closest: li; scroll: true"
                        >
                            <ul className="uk-navbar-nav">
                                <li>
                                    <Link to="/#a-propos">À PROPOS</Link>
                                </li>
                                <li>
                                    <Link to="/#n7-com">COMMUNICATION</Link>
                                </li>
                                <li>
                                    <Link to="/#n7-media">PUBLICITE</Link>
                                </li>
                                <li>
                                    <Link to="/#n7-africa">
                                        MARKETING DIGITAL
                                    </Link>
                                </li>
                                {/* <li>
                    <Link to="/#realisations">RÉALISATIONS</Link>
                </li> */}
                                {/* <li>
                    <Link to="/#references">RÉFÉRENCES</Link>
                </li> */}
                                <li>
                                    <Link to="/#blog">BLOG</Link>
                                </li>
                                <li>
                                    <Link to="/#contact">CONTACT</Link>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                {/* End Mobile Navbar */}

                {/* Start Navbar Area */}
                <header
                    className="header-area uk-dark"
                    data-uk-sticky="top: 100vh; animation: uk-animation-slide-top;"
                >
                    <div className="uk-container">
                        <div className="uk-navbar">
                            <div className="logo uk-navbar-left">
                                <a href="/" className="link logo-placeholder">
                                    <img
                                        className="logo-img"
                                        src="/assets/images/logo.png"
                                        alt="logo"
                                    />
                                </a>
                            </div>

                            <div
                                className="uk-navbar-toggle"
                                id="navbar-toggle"
                                data-uk-toggle="target: #offcanvas-flip"
                            >
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>

                            <div className="navbar uk-navbar-right">
                                <nav
                                    className="uk-navbar-container"
                                    data-uk-scrollspy-nav="offset: 0; closest: li; scroll: true"
                                >
                                    <ul className="uk-navbar-nav">
                                        <li>
                                            <Link to="/#a-propos">
                                                À PROPOS
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/#n7-com">
                                                COMMUNICATION
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/#n7-media">
                                                PUBLICITE
                                            </Link>
                                        </li>
                                        <li>
                                            <Link to="/#n7-africa">
                                                MARKETING DIGITAL
                                            </Link>
                                        </li>
                                        {/* <li>
                        <Link to="/#realisations">RÉALISATIONS</Link>
                    </li> */}
                                        {/* <li>
                        <Link to="/#references">RÉFÉRENCES</Link>
                    </li> */}
                                        <li>
                                            <Link to="/#blog">BLOG</Link>
                                        </li>
                                        <li>
                                            <Link to="/#contact">CONTACT</Link>
                                        </li>
                                    </ul>
                                </nav>

                                <div className="lang">
                                    <form>
                                        <div>
                                            <select>
                                                <option value="fr">Fr</option>
                                                <option value="ar">Ar</option>
                                            </select>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                {/* End Navbar Area */}
            </>
        )
    }
}

export default withRouter(Navigation)
