import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../Common/Footer'
import Requestquote from '../../Common/Requestquote'

class ProjectDetails extends React.Component {
    render() {
        return (
            <>
                {/* <!-- Start Page Title Area --> */}
                <section className="page-title-area uk-page-title">
                    <div className="uk-container  ">
                        <h1>Stratégie de Communication </h1>
                        <ul>
                            <li>
                                <Link to="/">Communication</Link>
                            </li>
                            <li>Stratégie de Communication</li>
                        </ul>
                    </div>
                </section>
                {/* <!-- End Page Title Area --> */}

                {/* <!-- Start Project Details Area --> */}
                <section className="project-details-area uk-project-details uk-section">
                    <div className="uk-container">
                        <div className="uk-grid uk-flex project-details">
                            <div className="project-details-img uk-width-expand">
                                <img
                                    src="/assets/images/imgcm/StrategiedeMarque.jpg"
                                    alt="project"
                                />
                            </div>

                            <div className="item uk-width-1-5">
                                <div className="project-details-info">
                                    <ul>
                                        {/* <li><span>Customer Name:</span> Steven John</li> */}
                                        <li>
                                            <span>Catégorie:</span>{' '}
                                            Communication
                                        </li>

                                        {/* <li><span>Tags:</span> <Link to="#">Art</Link>, <Link to="#">Design</Link></li> */}
                                        <li>
                                            <span>Partager:</span>

                                            <ul>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-logo"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-twitter"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-logo-1"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-linkedin"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="project-details-desc">
                            <h3>Stratégie de Communication </h3>
                            <h5>
                                Nos consultants analysent vos briefs, la data de
                                votre marché et votre vision globale avant
                                d'implémenter des stratégies fortes et
                                percutantes et garantir à vos marques une
                                notoriété solide et pérenne.
                            </h5>
                            <h5>
                                Grâce à la technologie, nous développons des
                                stratégies de positionnement, des architectures
                                de campagnes et des tactiques d'influence qui
                                renforcent durablement l'image des marques.
                            </h5>
                            <h5>
                                Nous aidons nos clients annonceurs à se
                                démarquer en alliant notre profonde
                                compréhension des cibles à une analyse des
                                données de marché complète et rigoureuse ; tout
                                en gardant à l'esprit le challenge média à
                                atteindre.
                            </h5>
                        </div>

                        <Requestquote />
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}

export default ProjectDetails
