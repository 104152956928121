import React from 'react'
import LaxButton from '../Common/LaxButton'

let lax = null
if (typeof document !== 'undefined') {
    lax = require('lax.js')
}

class About extends React.Component {
    constructor(props) {
        super(props)
        if (lax) this.initLax()
    }

    initLax() {
        lax.setup()
        document.addEventListener(
            'scroll',
            x => {
                lax.update(window.scrollY)
            },
            false
        )
        lax.update(window.scrollY)
    }

    render() {
        return (
            <section id="about" className="uk-about about-area uk-section">
                <div className="uk-container">
                    <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                        <div className="item">
                            <div className="about-content">
                                <div className="uk-section-title section-title">
                                    <span>À Propos de nous</span>
                                    <h2>
                                        BOOSTEZ VOS PERFORMANCES,
                                        <br />
                                        EN UN CLIC !
                                    </h2>
                                    <div className="bar"></div>
                                </div>

                                <p className="text-black">
                                    Nous mettons nos compétences et notre
                                    expertise métier au service de nos clients,
                                    leurs permettant de déployer efficacement
                                    leurs stratégies de communication, et
                                    d’optimiser leur media buying, avec
                                    efficacité et performance sur l’ensemble des
                                    médias nationaux et internationaux. Mettant
                                    la qualité du management, l’efficacité des
                                    process, et la relation client dans le coeur
                                    de notre activité, notre groupe est le
                                    premier de sa catégorie à être certifié ISO
                                    par le bureau VERITAS pour la qualité de ses
                                    services.
                                </p>

                                <div className="about-text">
                                    <div className="icon">
                                        <i className="flaticon-quality"></i>
                                    </div>
                                    <h3>
                                        1<sup>er</sup> groupe de communication
                                        au Maroc certifié ISO par le Bureau
                                        Veritas pour la qualité de ses services.
                                    </h3>
                                </div>
                                <div className="signature">
                                    <a
                                        href="http://www.bureauveritas.ma"
                                        rel="noopener noreferrer"
                                        target="_blank"
                                    >
                                        <img
                                            src="/assets/images/signature.png"
                                            alt="signature"
                                        />
                                    </a>
                                </div>
                            </div>
                        </div>

                        <div className="item">
                            <div className="about-img">
                                <picture className="about-img1">
                                    <source srcSet="/assets/images/about1.webp" type="image/webp" />
                                    <source srcSet="/assets/images/about1.jpg" type="image/jpeg" />
                                    <img src="/assets/images/about1.jpg" alt="about-img" />
                                </picture>
                                <LaxButton />
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default About
