import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../Common/Footer'
import Requestquote from '../../Common/Requestquote'

class ProjectDetails extends React.Component {
    render() {
        return (
            <>
                {/* <!-- Start Page Title Area --> */}
                <section className="page-title-area uk-page-title">
                    <div className="uk-container">
                        <h1>VIDEO PRODUCTION </h1>
                        <ul>
                            <li>
                                <Link to="/">Communication</Link>
                            </li>
                            <li>VIDEO PRODUCTION </li>
                        </ul>
                    </div>
                </section>
                {/* <!-- End Page Title Area --> */}

                {/* <!-- Start Project Details Area --> */}
                <section className="project-details-area uk-project-details uk-section">
                    <div className="uk-container">
                        <div className="uk-grid uk-flex project-details">
                            <div className="project-details-img uk-width-expand">
                                <img
                                    src="/assets/images/imgcm/EReputation.jpg"
                                    alt="project"
                                />
                            </div>

                            <div className="item uk-width-1-5">
                                <div className="project-details-info">
                                    <ul>
                                        {/* <li><span>Customer Name:</span> Steven John</li> */}
                                        <li>
                                            <span>Catégorie:</span>{' '}
                                            Communication
                                        </li>

                                        {/* <li><span>Tags:</span> <Link to="#">Art</Link>, <Link to="#">Design</Link></li> */}
                                        <li>
                                            <span>Partager:</span>

                                            <ul>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-logo"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-twitter"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-logo-1"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-linkedin"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="project-details-desc">
                            <h3>VIDEO PRODUCTION </h3>
                            <h5>
                                Pour un succès assuré de vos campagnes, nous
                                vous apportons notre solide expérience dans la
                                production audiovisuelle.
                            </h5>
                            <h5>
                                Pour tous vos spots TV, vos films
                                institutionnels ou vos capsules digitales, nous
                                vous accompagnons de la réflexion stratégique à
                                la conceptualisation de votre projet.
                            </h5>
                            <h5>
                                Dotés des équipements de dernière technologie,
                                et de solides partenariats avec des réalisateurs
                                de renom et des managers de stars, nous livrons
                                des productions clé en main, qui allient des
                                têtes d’affiches, des techniques de tournage les
                                plus pointues (drônes, prises de vue aériennes
                                et sous-marines, effets spéciaux, etc…).
                            </h5>
                            <h5>
                                Nous mettons également à la disposition de vos
                                événements corportate un grand stock d’écrans
                                géants à LED de dernière génération, à exploiter
                                en solution d’affichage de scène indoor et en
                                outdoor.
                            </h5>
                            <h5>
                                Nos équipes vous assurent repérage,
                                installation, et maintenance que ce soit à la
                                location ou à la vente partout sur le territoire
                                marocain.
                            </h5>
                        </div>

                        <Requestquote />
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}

export default ProjectDetails
