import React from 'react'

class Features extends React.Component {
    render() {
        return (
            <section className="uk-features features-area uk-section uk-padding-remove-top">
                <div className="uk-container">
                    <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m">
                        <div className="uk-item">
                            <div className="flip-card">
                                <div className="flip-card-inner">
                                    <div className="flip-card-front front-image">
                                        {/* <img src={flip1} /> */}
                                        <h1>COMMUNICATION</h1>
                                    </div>
                                    <div className="flip-card-back single-features-box1 box-rose">
                                        <div className="single-features-box1  communication">
                                            <div className="custom-icon" />
                                            <div className="text-content">
                                                <h3>N7 Communication</h3>
                                                <div className="bar"></div>
                                                <p>
                                                    Conseils en stratégies de
                                                    communication, Production
                                                    publicitaire, Street
                                                    Marketing, Solutions
                                                    d’impressions, Gestion des
                                                    relations presse, RP et
                                                    Communication d’influence.
                                                </p>
                                            </div>

                                            <div className="dot-img">
                                                <img
                                                    src="/assets/images/dot.png"
                                                    alt="dot"
                                                    className="color-dot"
                                                />
                                                <img
                                                    src="/assets/images/white-dot.png"
                                                    alt="dot"
                                                    className="white-dot"
                                                />
                                            </div>
                                            <div className="animation-img">
                                                <img
                                                    src="/assets/images/shape1.svg"
                                                    alt="shape"
                                                />
                                                <img
                                                    src="/assets/images/shape2.svg"
                                                    alt="shape"
                                                />
                                                <img
                                                    src="/assets/images/shape3.svg"
                                                    alt="shape"
                                                />
                                                <img
                                                    src="/assets/images/shape1.svg"
                                                    alt="shape"
                                                />
                                                <img
                                                    src="/assets/images/shape2.svg"
                                                    alt="shape"
                                                />
                                                <img
                                                    src="/assets/images/shape3.svg"
                                                    alt="shape"
                                                />
                                                <img
                                                    src="/assets/images/shape1.svg"
                                                    alt="shape"
                                                />
                                                <img
                                                    src="/assets/images/shape3.svg"
                                                    alt="shape"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="uk-item">
                            <div className="flip-card">
                                <div className="flip-card-inner">
                                    <div className=" flip-card-front2 front-image">
                                        {/* <img src={flip2} /> */}
                                        <h1>PUBLICITE</h1>
                                    </div>
                                    <div className="flip-card-back single-features-box2 box-rose">
                                        <div className="single-features-box2 media ">
                                            <div className="custom-icon" />
                                            <div className="text-content">
                                                <h3>PUBLICITE</h3>
                                                <div className="bar"></div>
                                                <p>
                                                    Agence média totalement
                                                    intégrée, N7 MEDIA est
                                                    spécialisée dans
                                                    l’implémentation de
                                                    stratégies médias sur les
                                                    principaux médias nationaux
                                                    et internationaux, assurant
                                                    une prise en charge
                                                    intégrale des campagnes
                                                    publicitaires, aussi bien
                                                    sur la Télévision, la Radio,
                                                    la Presse, l’Affichage et le
                                                    Digital.
                                                </p>
                                            </div>

                                            <div className="dot-img">
                                                <img
                                                    src="/assets/images/dot.png"
                                                    alt="dot"
                                                    className="color-dot"
                                                />
                                                <img
                                                    src="/assets/images/white-dot.png"
                                                    alt="dot"
                                                    className="white-dot"
                                                />
                                            </div>
                                            <div className="animation-img">
                                                <img
                                                    src="/assets/images/shape1.svg"
                                                    alt="shape"
                                                />
                                                <img
                                                    src="/assets/images/shape2.svg"
                                                    alt="shape"
                                                />
                                                <img
                                                    src="/assets/images/shape3.svg"
                                                    alt="shape"
                                                />
                                                <img
                                                    src="/assets/images/shape1.svg"
                                                    alt="shape"
                                                />
                                                <img
                                                    src="/assets/images/shape2.svg"
                                                    alt="shape"
                                                />
                                                <img
                                                    src="/assets/images/shape3.svg"
                                                    alt="shape"
                                                />
                                                <img
                                                    src="/assets/images/shape1.svg"
                                                    alt="shape"
                                                />
                                                <img
                                                    src="/assets/images/shape3.svg"
                                                    alt="shape"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="uk-item">
                            <div className="flip-card">
                                <div className="flip-card-inner">
                                    <div className="flip-card-front3 front-image">
                                        {/* <img src={flip3} /> */}
                                        <h1>MARKETING DIGITAL</h1>
                                    </div>
                                    <div className="flip-card-back single-features-box3 box-rose">
                                        <div className="single-features-box3 africa">
                                            <div className="custom-icon" />
                                            <div className="text-content">
                                                <h3>MARKETING DIGITAL</h3>
                                                <div className="bar"></div>
                                                <p>
                                                    Agence de marketing digial
                                                    specialisée en stratégies
                                                    d'acquisition et solutions
                                                    publicitaires digitales.
                                                </p>
                                            </div>

                                            <div className="dot-img">
                                                <img
                                                    src="/assets/images/dot.png"
                                                    alt="dot"
                                                    className="color-dot"
                                                />
                                                <img
                                                    src="/assets/images/white-dot.png"
                                                    alt="dot"
                                                    className="white-dot"
                                                />
                                            </div>
                                            <div className="animation-img">
                                                <img
                                                    src="/assets/images/shape1.svg"
                                                    alt="shape"
                                                />
                                                <img
                                                    src="/assets/images/shape2.svg"
                                                    alt="shape"
                                                />
                                                <img
                                                    src="/assets/images/shape3.svg"
                                                    alt="shape"
                                                />
                                                <img
                                                    src="/assets/images/shape1.svg"
                                                    alt="shape"
                                                />
                                                <img
                                                    src="/assets/images/shape2.svg"
                                                    alt="shape"
                                                />
                                                <img
                                                    src="/assets/images/shape3.svg"
                                                    alt="shape"
                                                />
                                                <img
                                                    src="/assets/images/shape1.svg"
                                                    alt="shape"
                                                />
                                                <img
                                                    src="/assets/images/shape3.svg"
                                                    alt="shape"
                                                />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Features
