import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../Common/Footer'
import Requestquote from '../../Common/Requestquote'

class ProjectDetails extends React.Component {
    render() {
        return (
            <>
                {/* <!-- Start Page Title Area --> */}
                <section className="page-title-area uk-page-title">
                    <div className="uk-container">
                        <h1>Production Publicitaire</h1>
                        <ul>
                            <li>
                                <Link to="/">Communication</Link>
                            </li>
                            <li>Production Publicitaire</li>
                        </ul>
                    </div>
                </section>
                {/* <!-- End Page Title Area --> */}

                {/* <!-- Start Project Details Area --> */}
                <section className="project-details-area uk-project-details uk-section">
                    <div className="uk-container">
                        <div className="uk-grid uk-flex project-details">
                            <div className="project-details-img uk-width-expand">
                                <img
                                    src="/assets/images/imgcm/ProductionPub.jpg"
                                    alt="project"
                                />
                            </div>

                            <div className="item uk-width-1-5">
                                <div className="project-details-info">
                                    <ul>
                                        {/* <li><span>Customer Name:</span> Steven John</li> */}
                                        <li>
                                            <span>Catégorie:</span>{' '}
                                            Communication
                                        </li>

                                        {/* <li><span>Tags:</span> <Link to="#">Art</Link>, <Link to="#">Design</Link></li> */}
                                        <li>
                                            <span>Partager:</span>

                                            <ul>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-logo"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-twitter"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-logo-1"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-linkedin"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="project-details-desc">
                            <h3>Production Publicitaire</h3>
                            <h5>
                                Des annonceurs de renom et des chantiers
                                colossaux se sont équipés de nos plus belles
                                créations.
                            </h5>
                            <h5>
                                Entre mobilier urbain, panneaux publicitaires,
                                signalétique, et palissades de chantiers ; nos
                                ateliers mettent notre savoir-faire ancestral
                                dans la production publicitaire au service des
                                marques
                            </h5>
                            <h5>
                                Grâce à l’expertise de nos équipes de
                                production, nous promettons à nos annonceurs des
                                expériences audacieuses, out of the box,
                                percutantes et uniques, entre Spots Télévisions,
                                Spots Radios, films institutionnels et capsules
                                digitales ; notre signature a accompagné les
                                meilleurs annonceurs et s’est vu décerné de très
                                grandes distinctions.
                            </h5>
                            <h5>
                                Vous avez des publicités à produire et des
                                chantiers à équiper, contactez-nous !
                            </h5>
                        </div>

                        <Requestquote />
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}

export default ProjectDetails
