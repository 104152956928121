import React from 'react'
import { Link } from 'react-router-dom'

class Requestquote extends React.Component {
    render() {
        return (
            <div className="project-next-and-prev">
                <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s">
                    <div className="item">
                        <h3>Demandez votre devis</h3>
                        <div className="single-footer-widget ">
                            <div className="bar"></div>
                            <p>
                                Discutons de la meilleure façon de vous aider à
                                vous démarquer.
                            </p>
                        </div>
                    </div>

                    <div className="item uk-text-right">
                        <Link
                            to={`/contact?referral=${
                                window ? window.location.pathname : ''
                            }`}
                            className="uk-button uk-button-default"
                        >
                            Consultez nos experts
                        </Link>
                    </div>
                </div>
            </div>
        )
    }
}

export default Requestquote
