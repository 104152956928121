import React from 'react'
import { withRouter } from 'react-router-dom'
import ReactModal from 'react-modal'

class Services extends React.Component {
    render() {
        let { modalText, modalTitre } = this.state
        return (
            <section
                id="services"
                className="services-area uk-services uk-section"
            >
                <ReactModal
                    isOpen={this.state.showModal}
                    contentLabel="Modal #1 Global Style Override Example"
                    onRequestClose={this.handleCloseModal}
                    className="services-modal-box"
                >
                    <button
                        onClick={this.handleCloseModal}
                        className="modal-close-btn"
                    >
                        <i className="flaticon-close"></i>
                    </button>

                    <div className="box1">
                        <h2>{modalTitre}</h2>
                        <p>{modalText}</p>
                    </div>

                    <div className="box2">
                        <a
                            href={`/contact?referral=${
                                window ? window.location.pathname : ''
                            }`}
                            className="uk-button uk-button-default"
                        >
                            Demander un devis
                        </a>
                    </div>
                </ReactModal>

                <div className="uk-container">
                    <div className="uk-section-title section-title" id="n7-com">
                        <span>Services</span>
                        <h2>N7 Communication</h2>
                        <div className="bar"></div>
                        <br />
                        <p style={{ display: 'inline' }} className="text-black">
                            Conseils en stratégies de communication, Production
                            publicitaire,{' '}
                            <a
                                className="show anotblock"
                                onClick={e =>
                                    this.toggleShowing(e, 'paragraph-com')
                                }
                                style={{ display: 'inline' }}
                            >
                                {' '}
                                lire la suite ...
                            </a>
                        </p>
                        <p id="paragraph-com" className="hide text-black">
                            Street Marketing, Solutions d’impressions, Gestion
                            des relations presse, RP et Communication
                            d’influence.
                        </p>
                        <div className="bar"></div>
                    </div>

                    <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
                        <div className="item">
                            <div className="single-services">
                                <div className="icon custom-icon active street-marketing"></div>
                                <h3>Stratégie de Marque</h3>

                                <i className="flaticon-right link-btn"></i>
                                <a
                                    href="/Strategie-De-Marque"
                                    className="link uk-position-cover"
                                ></a>
                            </div>
                        </div>
                        <div className="item">
                            <div className="single-services">
                                <div className="icon custom-icon active creation-artistique"></div>
                                <h3>Création Artistique</h3>

                                <i className="flaticon-right link-btn"></i>
                                <a
                                    href="/Creation-Artistique"
                                    className="link uk-position-cover"
                                ></a>
                            </div>
                        </div>

                        <div className="item">
                            <div className="single-services">
                                <div className="icon custom-icon active communication-d-influence-and-relations-presse">
                                    {/* <FontAwesomeIcon icon={faPlus} size="2x"/> */}
                                </div>
                                <h3>Communication d'influence</h3>

                                <i className="flaticon-right link-btn"></i>
                                <a
                                    href="/Communication-d-influence"
                                    className="link uk-position-cover"
                                ></a>
                            </div>
                        </div>

                        <div className="item">
                            <div className="single-services">
                                <div className="icon custom-icon active communication-d-influence-and-relations-presse"></div>
                                <h3>Achats d’espaces Publicitaires</h3>

                                <i className="flaticon-right link-btn"></i>
                                <a
                                    href="/Achat-Espace-Publicitaire"
                                    className="link uk-position-cover"
                                ></a>
                            </div>
                        </div>

                        <div className="item">
                            <div className="single-services">
                                <div className="icon custom-icon active production-publicitaire"></div>
                                <h3>Production Publicitaire</h3>

                                <i className="flaticon-right link-btn"></i>
                                <a
                                    href="/Production-Publicitaire"
                                    className="link uk-position-cover"
                                ></a>
                            </div>
                        </div>

                        <div className="item">
                            <div className="single-services">
                                <div className="icon custom-icon active web"></div>
                                <h3>Street Marketing </h3>

                                <i className="flaticon-right link-btn"></i>
                                <a
                                    href="/Street-Marketing"
                                    className="link uk-position-cover"
                                ></a>
                            </div>
                        </div>

                        <div className="item">
                            <div className="single-services">
                                <div className="icon custom-icon active web-design-and-videos"></div>
                                <h3>Web Design</h3>

                                <i className="flaticon-right link-btn"></i>
                                <a
                                    href="/Web-Design"
                                    className="link uk-position-cover"
                                ></a>
                            </div>
                        </div>

                        <div className="item">
                            <div className="single-services">
                                <div className="icon custom-icon active veille-and-e-reputation"></div>
                                <h3>Vidéo Production</h3>

                                <i className="flaticon-right link-btn"></i>
                                <a
                                    href="/Video-Production"
                                    className="link uk-position-cover"
                                ></a>
                            </div>
                        </div>

                        <div className="item">
                            <div className="single-services">
                                <div className="icon custom-icon active social-media"></div>
                                <h3>Etudes et Evaluations</h3>

                                <i className="flaticon-right link-btn"></i>
                                <a
                                    href="/Etudes-Evaluations"
                                    className="link uk-position-cover"
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="uk-container">
                    <div
                        className="uk-section-title section-title"
                        id="n7-media"
                    >
                        <span>Services</span>
                        <h2>PUBLICITÉ</h2>
                        <div className="bar"></div>
                        <br />
                        <p style={{ display: 'inline' }} className="text-black">
                            Agence média totalement intégrée, N7 MEDIA est{' '}
                            <a
                                className="show anotblock"
                                onClick={e =>
                                    this.toggleShowing(e, 'paragraph-media')
                                }
                                style={{ display: 'inline' }}
                            >
                                {' '}
                                lire la suite ...
                            </a>
                        </p>
                        <p id="paragraph-media" className="hide text-black">
                            spécialisée dans l’implémentation de stratégies
                            médias sur les principaux médias nationaux et
                            internationaux, assurant une prise en charge
                            intégrale des campagnes publicitaires, aussi bien
                            sur la Télévision, la Radio, la Presse, l’Affichage
                            et le Digital.
                        </p>
                    </div>

                    <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
                        <div className="item">
                            <div className="single-services">
                                <div className="icon custom-icon active affichage"></div>
                                <h3>Affichage Publicitaire </h3>

                                <i className="flaticon-right link-btn"></i>

                                <a
                                    href="/Buying-Media-Affichage"
                                    className="link uk-position-cover"
                                ></a>
                            </div>
                        </div>

                        <div className="item">
                            <div className="single-services">
                                <div className="icon custom-icon active cinema"></div>
                                <h3>Cinéma </h3>

                                <i className="flaticon-right link-btn"></i>

                                <a
                                    href="/Cinema"
                                    className="link uk-position-cover"
                                ></a>
                            </div>
                        </div>

                        <div className="item">
                            <div className="single-services">
                                <div className="icon custom-icon active web"></div>
                                <h3>Publicité en ligne </h3>

                                <i className="flaticon-right link-btn"></i>

                                <a
                                    href="/Publicite-En-Ligne"
                                    className="link uk-position-cover"
                                ></a>
                            </div>
                        </div>

                        <div className="item">
                            <div className="single-services">
                                <div className="icon custom-icon active presse"></div>
                                <h3>Presse</h3>

                                <i className="flaticon-right link-btn"></i>

                                <a
                                    href="/Buying-Media-Presse"
                                    className="link uk-position-cover"
                                ></a>
                            </div>
                        </div>

                        <div className="item">
                            <div className="single-services">
                                <div className="icon custom-icon active web"></div>
                                <h3>Radio </h3>

                                <i className="flaticon-right link-btn"></i>

                                <a
                                    href="/Media-Radio"
                                    className="link uk-position-cover"
                                ></a>
                            </div>
                        </div>

                        <div className="item">
                            <div className="single-services">
                                <div className="icon custom-icon active television">
                                    <div></div>
                                </div>
                                <h3>Télévision</h3>

                                <i className="flaticon-right link-btn"></i>

                                <a
                                    href="/Media-tv"
                                    className="link uk-position-cover"
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="uk-container">
                    <div
                        className="uk-section-title section-title"
                        id="n7-africa"
                    >
                        <span>Services</span>
                        <h2>Marketing Digital</h2>
                        <div className="bar"></div>
                        <br />
                        <p style={{ display: 'inline' }} className="text-black">
                            Agence digitale spécialisée en stratégies et
                            solutions{' '}
                            <a
                                className="show anotblock"
                                onClick={e =>
                                    this.toggleShowing(e, 'paragraph-africa')
                                }
                                style={{ display: 'inline' }}
                            >
                                {' '}
                                lire la suite ...
                            </a>
                        </p>
                        <p id="paragraph-africa" className="hide text-black">
                            d’acquisition (Google Ads, Facebook Ads, YouTube
                            Ads, LinkedIn Ads,…). La performance des campagnes
                            et l’excellence des services font partie de notre
                            ADN.
                        </p>
                    </div>

                    <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
                        <div className="item">
                            <div className="single-services">
                                <div className="icon custom-icon active international"></div>
                                <h3>Stratégie de Marketing Digital</h3>

                                <i className="flaticon-right link-btn"></i>

                                <a
                                    href="/Stratégie-Marketing-Digital"
                                    className="link uk-position-cover"
                                ></a>
                            </div>
                        </div>

                        <div className="item">
                            <div className="single-services">
                                <div className="icon custom-icon active communication-d-influence-and-relations-presse"></div>
                                <h3>Publicité en ligne</h3>

                                <i className="flaticon-right link-btn"></i>

                                <a
                                    href="/Digital-Publicite-En-Ligne"
                                    className="link uk-position-cover"
                                ></a>
                            </div>
                        </div>

                        <div className="item">
                            <div className="single-services">
                                <div className="icon custom-icon active production-publicitaire-o"></div>
                                <h3>Social Média Management</h3>

                                <i className="flaticon-right link-btn"></i>

                                <a
                                    href="/Social-Media"
                                    className="link uk-position-cover"
                                ></a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }

    constructor() {
        super()

        this.state = {
            showModal: false,
            modalText: '',
            modalTitre: ''
        }

        this.handleOpenModal = this.handleOpenModal.bind(this)
        this.handleCloseModal = this.handleCloseModal.bind(this)
        this.handleDemand = this.handleDemand.bind(this)
    }

    toggleShowing(event, elementId) {
        event.target.style.display = 'none'
        let el = document.querySelector(`#${elementId}`)
        el.classList.toggle('show')
        el.classList.toggle('hide')
    }

    handleOpenModal(title, message) {
        let el = document.querySelector('#root')
        el.classList.add('back-blur')

        this.setState({
            showModal: true,
            modalTitre: title,
            modalText: message
        })
    }

    handleCloseModal() {
        let el = document.querySelector('#root')
        el.classList.remove('back-blur')
        this.setState({ showModal: false })
    }

    handleDemand() {
        this.handleCloseModal()
        this.props.history.push('/contact')
    }
}
export default withRouter(Services)
