import React from 'react'
import { Link } from 'react-router-dom'
import 'isomorphic-fetch'
import { ApiAddContact } from '../../tools/ApiServices'
class Contact extends React.Component {
    state = {
        submitting: false,
        submitted: false,
        buttonState: '',
        form: {
            name: '',
            email: '',
            subject: '',
            phone: '',
            message: ''
        }
    }

    onSubmit = e => {
        e.preventDefault()
        const { form } = this.state
        ApiAddContact(form).then(data => {
            this.setState({ submitted: true })
            alert('Votre formulaire a bien été enregistré')
            let form = Object.assign({}, this.state.form)
            form.name = ''
            form.email = ''
            form.phone = ''
            form.subject = ''
            form.message = ''
            this.setState({ form })
        })
    }

    nameChangeHandler = e => {
        let form = Object.assign({}, this.state.form)
        form.name = e.target.value
        this.setState({ form })
    }

    emailChangeHandler = e => {
        let form = Object.assign({}, this.state.form)
        form.email = e.target.value
        this.setState({ form })
    }

    phoneChangeHandler = e => {
        let form = Object.assign({}, this.state.form)
        form.phone = e.target.value
        this.setState({ form })
    }

    subjectChangeHandler = e => {
        let form = Object.assign({}, this.state.form)
        form.subject = e.target.value
        this.setState({ form })
    }

    messageChangeHandler = e => {
        let form = Object.assign({}, this.state.form)
        form.message = e.target.value
        this.setState({ form })
    }

    onHideSuccess = () => {
        this.setState({ submitted: false })
    }

    successMessage = () => {
        if (this.state.submitted) {
            return (
                <div className="alert-success" uk-alert-success>
                    <Link
                        to="#"
                        onClick={this.onHideSuccess}
                        className="uk-alert-close"
                        uk-close="true"
                    ></Link>
                    <h3>Je vous remercie</h3>
                    <p>Nous allons vous connecter bientôt.</p>
                </div>
            )
        }
    }

    render() {
        return (
            <section
                id="contact"
                className="contact-area uk-contact uk-section"
            >
                <div className="uk-container">
                    <div className="uk-section-title section-title">
                        <span>des questions</span>
                        <h2>Contactez Nous</h2>
                    </div>

                    <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                        <div className="item">
                            <div className="map-img">
                                <picture className="about-img1">
                                    <source srcSet="/assets/images/map5.webp" type="image/webp" />
                                    <source srcSet="/assets/images/map5.png" type="image/jpeg" />
                                    <img src="/assets/images/map5.png" alt="map" />
                                </picture>
                            </div>
                        </div>

                        <div className="item">
                            <form id="contactForm" onSubmit={this.onSubmit}>
                                <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                                    <div className="item uk-margin">
                                        <input
                                            type="text"
                                            className="uk-input"
                                            name="name"
                                            id="name"
                                            placeholder="Nom"
                                            value={this.state.form.name}
                                            onChange={this.nameChangeHandler}
                                            required={true}
                                        />
                                    </div>

                                    <div className="item uk-margin">
                                        <input
                                            type="email"
                                            className="uk-input"
                                            name="email"
                                            id="email"
                                            placeholder="Email"
                                            value={this.state.form.email}
                                            onChange={this.emailChangeHandler}
                                            required={true}
                                        />
                                    </div>

                                    <div className="item uk-margin">
                                        <input
                                            type="text"
                                            className="uk-input"
                                            placeholder="Telephone"
                                            value={this.state.form.phone}
                                            onChange={this.phoneChangeHandler}
                                            required={true}
                                        />
                                    </div>

                                    <div className="item uk-margin">
                                        <input
                                            type="text"
                                            className="uk-input"
                                            name="subject"
                                            id="subject"
                                            placeholder="Sujet"
                                            value={this.state.form.subject}
                                            onChange={this.subjectChangeHandler}
                                            required={true}
                                        />
                                    </div>
                                </div>

                                <div className="item">
                                    <textarea
                                        name="message"
                                        className="uk-textarea"
                                        id="message"
                                        cols="30"
                                        rows="4"
                                        placeholder="Votre message"
                                        value={this.state.form.message}
                                        onChange={this.messageChangeHandler}
                                        required={true}
                                    />
                                </div>

                                <button
                                    type="submit"
                                    className="uk-button uk-button-default"
                                >
                                    Envoyer
                                </button>
                            </form>
                            {this.successMessage()}
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Contact
