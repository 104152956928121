export const addMinutes = (date, minutes) => {
    return new Date(date.getTime() + minutes * 60000)
}

export const stripHtml = text => text.replace(/<[^>]*>?/gm, '')

export const slugify = str => {
    str = str.replace(/^\s+|\s+$/g, '') // trim
    str = str.toLowerCase()

    // remove accents, swap ñ for n, etc
    var from = 'àáäâèéëêìíïîòóöôùúüûñç·/_,:;'
    var to = 'aaaaeeeeiiiioooouuuunc------'
    for (var i = 0, l = from.length; i < l; i++) {
        str = str.replace(new RegExp(from.charAt(i), 'g'), to.charAt(i))
    }

    str = str
        .replace(/[^a-z0-9 -]/g, '') // remove invalid chars
        .replace(/\s+/g, '-') // collapse whitespace and replace by -
        .replace(/-+/g, '-') // collapse dashes

    return str
}

export const truncaText = text => {
    if (text.length > 155) return text.substring(0, 155) + '...'
    else return text
}

export const getDayStart = date => {
    date.setHours(0, 0, 0, 0)
    return date
}

export const alterHour = (date, hour) => {
    date.setHours(hour)
    return date
}

export const setWeekDay = (date, daytoset) => {
    var currentDay = date.getDay()
    var distance = (daytoset + 7 - currentDay) % 7
    date.setDate(date.getDate() + distance)
    return date
}

export const wasWeeksAgo = (weeksAgo = 0, date) => {
    let specifiedDate = new Date(date)
    // Create a date
    var weekStart = new Date()
    // Set time to 00:00:00
    weekStart.setHours(0, 0, 0, 0)
    // Set to previous Sunday
    weekStart.setDate(weekStart.getDate() - weekStart.getDay())
    // Set to Sunday on weeksAgo
    weekStart.setDate(weekStart.getDate() - 7 * weeksAgo)
    // Create date for following Saturday at 24:00:00
    var weekEnd = new Date(+weekStart)
    weekEnd.setDate(weekEnd.getDate() + 7)
    // See if date is in that week
    return specifiedDate >= weekStart && specifiedDate <= weekEnd
}

export const formatDateForForm = (date, withoutTime = false) => {
    let dateObj = date
    if (typeof date != 'object') dateObj = new Date(dateObj)
    let dataString =
        dateObj.getFullYear() +
        '-' +
        ('0' + (dateObj.getMonth() + 1)).slice(-2) +
        '-' +
        ('0' + dateObj.getDate()).slice(-2)
    if (!withoutTime)
        return (
            dataString +
            'T' +
            ('0' + dateObj.getHours()).slice(-2) +
            ':' +
            ('0' + dateObj.getMinutes()).slice(-2)
        )
    else return dataString
}
export const getTimezone = () =>
    new Date()
        .toString()
        .match(/\((.*)\)/)
        .pop()
        .replace('GMT', '')

export const formatDate = (date, withTime = false, withTimeZone = false) => {
    var dateObj = date

    if (typeof date != 'object') {
        if (withTimeZone) dateObj = `${dateObj}+0000`
        dateObj = new Date(dateObj)
    }

    var day = dateObj.getDate()
    var monthIndex = dateObj.getMonth()
    var year = dateObj.getFullYear()

    let monthNames = [
        'Janvier',
        'Février',
        'Mars',
        'Avril',
        'Mai',
        'Juin',
        'Juillet',
        'Août',
        'Septembre',
        'Octobre',
        'Novembre',
        'Décembre'
    ]

    var dateString = day + ' ' + monthNames[monthIndex] + ' ' + year

    if (!day) return ' -- '

    if (withTime) {
        var hour = dateObj.getHours()
        var minute = dateObj.getMinutes()
        return `${dateString} ${hour}:${minute}`
    }

    return dateString
}

export const weekDayFromDate = date => {
    return date.getDay()
}

export const yearFromDate = date => {
    return date.getFullYear()
}

export const hourFromDate = date => {
    return date.getHours()
}
