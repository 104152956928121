import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../Common/Footer'
import { ApiGetBlogs, ApiCountBlogs } from '../../tools/ApiServices'
import { formatDate } from '../../tools/Helpers'

class Blog extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            limit: 10,
            page: 1,
            articles: [],
            count: 0,
            keyword: null,
            categoryId: null
        }
    }

    componentDidMount() {
        const urlParams = new URLSearchParams(this.props.location.search)
        const keyword = urlParams.get('keyword')
        const categoryId = urlParams.get('category')
        if (keyword && keyword != '')
            this.setState({ keyword }, this.getArticles)
        if (categoryId && categoryId != '')
            this.setState({ categoryId }, this.getArticles)
        else this.getArticles()
        this.getCountArticles()
    }

    getCountArticles() {
        ApiCountBlogs().then(data => {
            this.setState({
                count: data
            })
        })
    }

    getArticles() {
        const { page, limit, keyword, categoryId } = this.state
        ApiGetBlogs(page, limit, { keyword, categoryId }).then(data => {
            this.setState({
                articles: data
            })
        })
    }

    setPage(page) {
        this.setState({ page: page }, () => this.getArticles())
    }

    addDefaultSrc(ev) {
        ev.target.src = '/assets/images/placeholder.png'
        ev.target.onerror = null
    }

    render() {
        const { articles, page, limit, count } = this.state
        const isNextAvailable = page * limit < count

        return (
            <>
                <section className="page-title-area uk-page-title">
                    <div className="uk-container">
                        <h1>Blog</h1>
                        <ul>
                            <li>
                                <a href="/">Accueil</a>
                            </li>
                            <li>Blog</li>
                        </ul>
                    </div>
                </section>
                <section className="blog-area uk-blog uk-section">
                    <div className="uk-container">
                        <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-3@m uk-child-width-1-2@s">
                            {articles.map(item => (
                                <div
                                    className="single-blog-post"
                                    style={{ margin: '1rem 0' }}
                                >
                                    <div className="blog-post-image">
                                        <Link to={`/blog-details/${item.slug}`}>
                                            <img
                                                src={`${process.env.REACT_APP_API_URL}${item.image.url}`}
                                                onError={this.addDefaultSrc}
                                                alt={item.title}
                                            />
                                        </Link>
                                    </div>

                                    <div className="blog-post-content">
                                        <span className="date">
                                            {formatDate(item.created_at)}
                                        </span>
                                        <h3>
                                            <Link
                                                to={`/blog-details/${item.slug}`}
                                            >
                                                {item.title}
                                            </Link>
                                        </h3>
                                        <Link
                                            to={`/blog-details/${item.slug}`}
                                            className="read-more"
                                        >
                                            Lire la suite
                                        </Link>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <br />
                        <div style={{ width: '100%', textAlign: 'center' }}>
                            {isNextAvailable ? (
                                <button
                                    className="uk-button uk-button-default"
                                    onClick={e => this.setPage(page + 1)}
                                >
                                    CHARGER PLUS
                                </button>
                            ) : (
                                <></>
                            )}
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}

export default Blog
