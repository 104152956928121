import '../../../node_modules/uikit/dist/css/uikit.min.css'
import '../../assets/css/style.scss'
import '../../assets/css/responsive.scss'
import React from 'react'
import AnchorLink from 'react-anchor-link-smooth-scroll'
import { Link } from 'react-router-dom'

class Navigation extends React.Component {
    render() {
        return (
            <>
                {/* Start Mobile Navbar */}
                <div
                    id="offcanvas-flip"
                    className="mobile-navbar uk-mobile-navbar"
                    uk-offcanvas="flip: true; overlay: true"
                >
                    <div className="uk-offcanvas-bar">
                        <button
                            className="uk-offcanvas-close"
                            type="button"
                            uk-close="true"
                        ></button>

                        <nav
                            className="uk-navbar-container"
                            data-uk-scrollspy-nav="offset: 0; closest: li; scroll: true"
                        >
                            <ul className="uk-navbar-nav">
                                <li className="uk-active">
                                    <AnchorLink
                                        offset={() => 100}
                                        to="#a-propos"
                                    >
                                        À PROPOS
                                    </AnchorLink>
                                </li>
                                <li>
                                    <AnchorLink offset={() => 100} to="#n7-com">
                                        COMMUNICATION
                                    </AnchorLink>
                                </li>
                                <li>
                                    <AnchorLink
                                        offset={() => 100}
                                        to="#n7-media"
                                    >
                                        N7 MEDIA
                                    </AnchorLink>
                                </li>
                                <li>
                                    <AnchorLink
                                        offset={() => 100}
                                        to="#n7-africa"
                                    >
                                        N7 AFRICA
                                    </AnchorLink>
                                </li>
                                <li>
                                    <AnchorLink
                                        offset={() => 100}
                                        to="#realisations"
                                    >
                                        RÉALISATIONS
                                    </AnchorLink>
                                </li>
                                <li>
                                    <AnchorLink
                                        offset={() => 100}
                                        to="#references"
                                    >
                                        RÉFÉRENCES
                                    </AnchorLink>
                                </li>
                                <li>
                                    <AnchorLink
                                        offset={() => 100}
                                        to="#contact"
                                    >
                                        CONTACT
                                    </AnchorLink>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>
                {/* End Mobile Navbar */}

                {/* Start Navbar Area */}
                <header
                    className="header-area uk-dark"
                    data-uk-sticky="top: 100vh; animation: uk-animation-slide-top;"
                >
                    <div className="uk-container">
                        <div className="uk-navbar">
                            <div className="logo uk-navbar-left">
                                <Link
                                    onClick={() => window.location.refresh()}
                                    to="/home-three"
                                >
                                    <img
                                        src="/assets/images/logo.png"
                                        alt="logo"
                                    />
                                </Link>
                            </div>

                            <div
                                className="uk-navbar-toggle"
                                id="navbar-toggle"
                                uk-toggle="target: #offcanvas-flip"
                            >
                                <span></span>
                                <span></span>
                                <span></span>
                            </div>

                            <div className="navbar uk-navbar-right">
                                <nav
                                    className="uk-navbar-container"
                                    data-uk-scrollspy-nav="offset: 0; closest: li; scroll: true"
                                >
                                    <ul className="uk-navbar-nav">
                                        <li className="uk-active">
                                            <AnchorLink
                                                offset={() => 100}
                                                to="#a-propos"
                                            >
                                                À PROPOS
                                            </AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink
                                                offset={() => 100}
                                                to="#n7-com"
                                            >
                                                COMMUNICATION
                                            </AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink
                                                offset={() => 100}
                                                to="#n7-media"
                                            >
                                                N7 MEDIA
                                            </AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink
                                                offset={() => 100}
                                                to="#n7-africa"
                                            >
                                                ADVERWEB
                                            </AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink
                                                offset={() => 100}
                                                to="#realisations"
                                            >
                                                RÉALISATIONS
                                            </AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink
                                                offset={() => 100}
                                                to="#references"
                                            >
                                                RÉFÉRENCES
                                            </AnchorLink>
                                        </li>
                                        <li>
                                            <AnchorLink
                                                offset={() => 100}
                                                to="#contact"
                                            >
                                                CONTACT
                                            </AnchorLink>
                                        </li>
                                    </ul>
                                </nav>

                                <div className="lang">
                                    <form>
                                        <div>
                                            <select>
                                                <option value="eng">En</option>
                                                <option value="ge">Ge</option>
                                            </select>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </header>
                {/* End Navbar Area */}
            </>
        )
    }
}

export default Navigation
