import React from 'react'
import { withRouter } from 'react-router-dom'
import Banner from '../HomeTwo/Banner'
import Features from '../HomeTwo/Features'
import SeperatedBar from '../HomeTwo/SeperatedBar'
import About from '../HomeTwo/About'
import Services from '../HomeTwo/Services'
import Testimonials from '../HomeTwo/Testimonials'
import PartnerLogo from '../HomeTwo/PartnerLogo'
import Newsletter from '../HomeTwo/Newsletter'
import LatestNews from '../HomeTwo/LatestNews'
import Contact from '../HomeTwo/Contact'
import Footer from '../Common/Footer'

class Home extends React.Component {
    componentDidMount() {
        this.scrollToHash()
        this.props.history.listen((location, action) => {
            setTimeout(() => this.scrollToHash(), 0)
        })
    }

    scrollToHash() {
        let hash = window.location.hash
        let sectionId = hash ? hash.replace('#', '') : null
        let element = sectionId ? document.getElementById(sectionId) : null
        if (element) {
            var top = element.offsetTop
            window.scrollTo({
                top: top - 100,
                behavior: 'smooth'
            })
        }
    }

    render() {
        return (
            <>
                {/* Main Banner */}
                <Banner />
                {/* Features Area */}
                <Features />
                {/* Vertical line */}
                <SeperatedBar />
                {/* About Area */}
                <section id="a-propos">
                    <About />
                </section>
                {/* Vertical line */}
                <SeperatedBar />
                {/* Services Area */}
                <Services />
                {/* Vertical line */}
                <SeperatedBar />
                <section id="realisations">
                    {/* Project Area */}
                    {/* <Project /> */}
                </section>
                {/* Vertical line */}
                <SeperatedBar />
                <section id="references">
                    {/* Feedback Area */}
                    <Testimonials />
                </section>
                {/* Partner Area */}
                <PartnerLogo />
                {/* Team Area */}
                {/* <Team /> */}
                {/* Subscribe Area */}
                <Newsletter />
                <section id="blog">
                    {/* Blog Area */}
                    <LatestNews />
                </section>
                {/* Vertical line */}
                <SeperatedBar />
                <section id="contact">
                    {/* Contact Area */}
                    <Contact />
                </section>
                {/* Footer Area */}
                <Footer />
            </>
        )
    }
}

export default withRouter(Home)
