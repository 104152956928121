import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../Common/Footer'

class ProjectDetails extends React.Component {
    render() {
        return (
            <>
                {/* <!-- Start Page Title Area --> */}
                <section className="page-title-area uk-page-title">
                    <div className="uk-container">
                        <h1>AdverWeb </h1>
                        <ul>
                            <li>
                                <Link to="/">N7 Media</Link>
                            </li>
                            <li>AdverWeb </li>
                        </ul>
                    </div>
                </section>
                {/* <!-- End Page Title Area --> */}

                {/* <!-- Start Project Details Area --> */}
                <section className="project-details-area uk-project-details uk-section">
                    <div className="uk-container">
                        <div className="uk-grid uk-flex project-details">
                            <div className="project-details-img uk-width-expand">
                                <img
                                    src="/assets/images/project-details.jpg"
                                    alt="project"
                                />
                            </div>

                            <div className="item uk-width-1-5">
                                <div className="project-details-info">
                                    <ul>
                                        {/* <li><span>Customer Name:</span> Steven John</li> */}
                                        <li>
                                            <span>Catégorie:</span> N7 Media
                                        </li>

                                        {/* <li><span>Tags:</span> <Link to="#">Art</Link>, <Link to="#">Design</Link></li> */}
                                        <li>
                                            <span>Partager:</span>

                                            <ul>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-logo"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-twitter"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-logo-1"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-linkedin"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="project-details-desc">
                            <h3>AdverWeb </h3>
                            <p>
                                Spécialisée dans les services du marketing
                                digital offrant des services de conception Web,
                                de référencement, de gestion des réseaux sociaux
                                et de conception graphique (ainsi que d’autres)
                                qui répondent à vos attentes et objectifs
                                commerciaux. AdverWeb combine stratégie,
                                technologie et conception pour créer des
                                produits et solutions digitales qui résolvent
                                des problèmes complexes. Contactez AdverWeb pour
                                vous aider à vous démarquer sur votre secteur
                                d’activité et à être pertinents vis à vis de
                                votre public et de vos prospects.
                            </p>
                        </div>

                        <div className="project-next-and-prev">
                            <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s">
                                <div className="item">
                                    <h3>Demander un devis</h3>
                                    <div className="single-footer-widget ">
                                        <div className="bar"></div>
                                        <p>
                                            Discutons de la façon dont nous
                                            pouvons vous aider à vous démarquer.
                                        </p>
                                    </div>
                                </div>

                                <div className="item uk-text-right">
                                    <Link
                                        to={`/contact?referral=${
                                            window
                                                ? window.location.pathname
                                                : ''
                                        }`}
                                        className="uk-button uk-button-default"
                                    >
                                        Demander un devis
                                    </Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}

export default ProjectDetails
