import React from 'react'
import '../../../node_modules/react-modal-video/scss/modal-video.scss'
import '../../assets/css/style.scss'
import '../../assets/css/responsive.scss'

import Carousel from 'react-multi-carousel'
// import 'react-multi-carousel/lib/styles.css'

const options = {
    items: 1,
    loop: true,
    nav: true,
    dots: true,
    smartSpeed: 2000,
    margin: 30,
    autoplayHoverPause: true,
    autoplay: true,
    navText: [
        "<i className='flaticon-back'></i>",
        "<i className='flaticon-right'></i>"
    ],
    responsive: {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 1
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1
        }
    }
}

class Testimonials extends React.Component {
    state = {
        isOpen: false
    }

    openModal = () => {
        this.setState({ isOpen: true })
    }

    render() {
        return (
            <section
                id="clients"
                className="feedback-area uk-section uk-feedback"
            >
                <div className="uk-container">
                    <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                        <div className="item">
                            <div className="feedback-img">
                                <img
                                    src="/assets/images/temoignages.webp"
                                    alt="Testimonials"
                                />
                            </div>
                        </div>

                        <div className="item">
                            <div className="feedback-inner">
                                <div className="uk-section-title section-title-feed">
                                    <span>TEMOIGNAGES</span>
                                    <div className="bar"></div>
                                </div>

                                <Carousel
                                    className="feedback-slides owl-carousel owl-theme"
                                    {...options}
                                >
                                    <div className="single-feedback">
                                        <i className="flaticon-quote"></i>
                                        <p style={{ fontSize: '18px' }}>
                                            Nous travaillons avec l'agence N7
                                            depuis quelques années, elle nous a
                                            accompagné dans le développement de
                                            notre stratégie de communication.
                                            Toujours de bons conseils, grâce à
                                            une équipe sérieuse, agile et
                                            efficace pilotée par une direction
                                            très active et réactive, N7 restait
                                            toujours ouverte à tous les
                                            challenges, ils ont su parfaitement
                                            nous épauler durant toutes nos
                                            campagnes. Un partenaire solide sur
                                            lequel on peut compter, nous le
                                            recommandons vivement.
                                        </p>

                                        <div className="client">
                                            <h3>Mohammed ZAOUDI</h3>
                                            <span>Directeur IGA</span>
                                        </div>
                                    </div>

                                    <div className="single-feedback">
                                        <i className="flaticon-quote"></i>
                                        <p style={{ fontSize: '18px' }}>
                                            Accompagner un annonceur à
                                            construire sa marque nécessite un
                                            professionnalisme manifeste, une
                                            compétence confirmée et une
                                            disponibilité volontaire , et c’est
                                            justement cette combinaison qu’on a
                                            du mal à trouver de nos jours. Armée
                                            de ces valeurs, N7 communication
                                            devrait à mon sens changer de nom,
                                            je l’appellerais N360, dans la
                                            mesure où cette agence construit une
                                            vision de communication globale pour
                                            son client et veille à
                                            l’optimisation de l’utilisation de
                                            ses ressources et à la réalisation
                                            d’une complémentarité de tous les
                                            canaux de communication, qu’il
                                            s’agisse des masses média, du
                                            digital, des RP ou encore de
                                            l’événementiel. Un grand merci à
                                            toute l’équipe N7 pour leur
                                            engagement indéfectible , du conseil
                                            à la réussite des actions, en
                                            passant par la réalisation effective
                                            et le suivi des plans d’actions.
                                            Merci encore de stresser à notre
                                            place.
                                        </p>

                                        <div className="client">
                                            <h3>ADIL BENJELLOUN</h3>
                                            <span>O'DASSIA</span>
                                        </div>
                                    </div>

                                    <div className="single-feedback">
                                        <i className="flaticon-quote"></i>
                                        <p style={{ fontSize: '18px' }}>
                                            Depuis sa création, nous avons
                                            co-construit avec N7 COM plusieurs
                                            projets et durant toutes ces années
                                            l’équipe N7 COM a su nous
                                            accompagner par son écoute, son
                                            engagement et sa bienveillance . La
                                            collaboration avec N7 COM est une
                                            vraie réussite je les recommande
                                            vivement et je les félicite pour
                                            cette belle évolution.
                                        </p>

                                        <div className="client">
                                            <h3>ZAGHRARY KARIMA</h3>
                                            <span>EQDOM</span>
                                        </div>
                                    </div>
                                </Carousel>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        )
    }
}

export default Testimonials
