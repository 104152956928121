import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../Common/Footer'
import Requestquote from '../../Common/Requestquote'

class ProjectDetails extends React.Component {
    render() {
        return (
            <>
                {/* <!-- Start Page Title Area --> */}
                <section className="page-title-area uk-page-title">
                    <div className="uk-container">
                        <h1>Affichage Publicitaire </h1>
                        <ul>
                            <li>
                                <Link to="/">Media</Link>
                            </li>
                            <li>Affichage Publicitaire </li>
                        </ul>
                    </div>
                </section>
                {/* <!-- End Page Title Area --> */}

                {/* <!-- Start Project Details Area --> */}
                <section className="project-details-area uk-project-details uk-section">
                    <div className="uk-container">
                        <div className="uk-grid uk-flex project-details">
                            <div className="project-details-img uk-width-expand">
                                <img
                                    src="/assets/images/imgcm/Affichage.jpg"
                                    alt="project"
                                />
                            </div>

                            <div className="item uk-width-1-5">
                                <div className="project-details-info">
                                    <ul>
                                        {/* <li><span>Customer Name:</span> Steven John</li> */}
                                        <li>
                                            <span>Catégorie:</span> Media
                                        </li>

                                        {/* <li><span>Tags:</span> <Link to="#">Art</Link>, <Link to="#">Design</Link></li> */}
                                        <li>
                                            <span>Partager:</span>

                                            <ul>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-logo"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-twitter"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-logo-1"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-linkedin"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="project-details-desc">
                            <h3>Affichage Publicitaire </h3>
                            <h5>
                                Investissez les rues, faites-vous voir et
                                laissez briller votre marque dans les artères
                                stratégiques et les zones à fort chalandise de
                                toutes les villes que vous ciblez.
                            </h5>
                            <h5>
                                Que ce soit au Maroc ou à l’étranger,
                                laissez-vous guider par notre expertise pointue
                                dans les métiers de l’affichage, et obtenez les
                                meilleures disponibilités grâce à notre réseau
                                de partenaires agrées.
                            </h5>
                            <h5>
                                Panneaux urbains, uni pôles, affichage dans les
                                aéroports, visibilité dans les gares
                                ferroviaires, et même de l’affichage mobile sur
                                Bus et camions,
                            </h5>
                            <h5>
                                Nous offrons les meilleurs emplacements avec des
                                mapping et des plans de géo localisation de vos
                                cibles ;
                            </h5>
                            <h5>
                                Nous vous proposons également l’opportunité
                                d’installer vos propres panneaux, kakémonos et
                                vos supports personnalisés. Et nous vous
                                assistons dans le montage de vos dossiers
                                d’autorisations administratives pour
                                l’exploitation des domaines publics et privés
                                que vous souhaitez.
                            </h5>
                            <h5>
                                Contactez nos conseillers et demandez vos dispos
                                !
                            </h5>
                        </div>

                        <Requestquote />
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}

export default ProjectDetails
