import React from 'react'
import { Link, withRouter } from 'react-router-dom'
import Footer from '../Common/Footer'
import { formatDate, stripHtml, truncaText } from '../../tools/Helpers'
import {
    ApiGetLastBlogs,
    ApiGetBlog,
    ApiGetCategories
} from '../../tools/ApiServices'

class BlogDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            keyword: null,
            article: {},
            categories: [],
            lastArticles: []
        }
    }

    addDefaultSrc(ev) {
        ev.target.src = '/assets/images/placeholder.png'
        ev.target.onerror = null
    }

    onFormSubmit = e => {
        e.preventDefault()
    }

    componentWillReceiveProps(newProps) {
        const {
            match: { params }
        } = newProps
        this.getArticle(params.slug)
    }

    componentDidMount() {
        const {
            match: { params }
        } = this.props
        this.getArticle(params.slug)
        this.getLastArticles()
        this.getCategories()
    }

    getArticle(slug) {
        ApiGetBlog(slug).then(data => {
            this.setState({
                article: data[0]
            })
        })
    }

    getLastArticles() {
        ApiGetLastBlogs().then(data => {
            this.setState({
                lastArticles: data
            })
        })
    }

    getCategories() {
        ApiGetCategories().then(data => {
            this.setState({
                categories: data
            })
        })
    }

    stringToDate(stringDate) {
        return new Date(stringDate)
    }

    getDay(dateObj) {
        return dateObj.getDay()
    }

    handleSearch = event => {
        this.setState({
            keyword: event.target.value
        })
    }

    getMonth(dateObj) {
        var monthNames = [
            'Janvier',
            'Février',
            'Mars',
            'Avril',
            'Mai',
            'Juin',
            'Juillet',
            'Août',
            'Novembre',
            'Octobre',
            'Septembre',
            'Décembre'
        ]
        return monthNames[dateObj.getMonth()]
    }

    render() {
        const { lastArticles, article, categories, keyword } = this.state

        let previousArticle = lastArticles.find(el => article.id > el.id)
        let nextArticle = lastArticles.find(el => article.id < el.id)

        return (
            <>
                {/*  Start Page Title Area */}
                <section className="page-title-area uk-page-title">
                    <div className="uk-container">
                        <h1>{article.title}</h1>
                        <ul>
                            <li>
                                <Link to="/">Accueil</Link>
                            </li>
                            <li>
                                <Link to="/blog">Blog</Link>
                            </li>
                        </ul>
                    </div>
                </section>
                {/* End Page Title Area */}
                {/*  Start Blog Details Area */}
                <section className="blog-details-area uk-blog-details uk-section">
                    <div className="uk-container">
                        <article className="uk-article blog-details">
                            <div className="uk-grid uk-flex">
                                <div className="inner uk-width-expand">
                                    <div className="article-img">
                                        <img
                                            src={`${process.env.REACT_APP_API_URL}${
                                                article.image
                                                    ? article.image.url
                                                    : '-'
                                            }`}
                                            onError={this.addDefaultSrc}
                                            alt={article.title}
                                        />
                                        <div className="date">
                                            {this.getDay(
                                                this.stringToDate(
                                                    article.created_at
                                                )
                                            )}
                                            <br />
                                            {this.getMonth(
                                                this.stringToDate(
                                                    article.created_at
                                                )
                                            )}
                                        </div>
                                    </div>

                                    <div className="article-content">
                                        <ul className="category">
                                            <li>
                                                <Link to="#">
                                                    {article.catalogue
                                                        ? article.catalogue
                                                              .label
                                                        : '---'}
                                                </Link>
                                            </li>
                                        </ul>
                                        <div
                                            dangerouslySetInnerHTML={{
                                                __html: article.content
                                            }}
                                        />
                                    </div>

                                    <div className="post-controls-buttons uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-2@s">
                                        <div className="item">
                                            {previousArticle ? (
                                                <Link
                                                    to={`/blog-details/${previousArticle.slug}`}
                                                    className="uk-button uk-button-default"
                                                >
                                                    Article Précèdent
                                                </Link>
                                            ) : (
                                                <></>
                                            )}
                                        </div>

                                        <div className="item uk-text-right">
                                            {nextArticle ? (
                                                <Link
                                                    to={`/blog-details/${nextArticle.slug}`}
                                                    className="uk-button uk-button-default"
                                                >
                                                    Article Suivant
                                                </Link>
                                            ) : (
                                                <></>
                                            )}
                                        </div>
                                    </div>
                                </div>

                                <div className="uk-sidebar uk-width-1-5">
                                    <div className="widget widget_search">
                                        <form>
                                            <input
                                                onChange={this.handleSearch}
                                                type="text"
                                                className="uk-input"
                                                placeholder="Tapez votre recherche ici..."
                                            />
                                            <Link
                                                to={`/blog?keyword=${keyword}`}
                                            >
                                                <button type="submit">
                                                    <i className="flaticon-search"></i>
                                                </button>
                                            </Link>
                                        </form>
                                    </div>

                                    <div className="widget widget_categories">
                                        <h3 className="widget-title">
                                            Categories
                                        </h3>
                                        <div className="bar"></div>

                                        <ul>
                                            {categories.map((item, key) => (
                                                <li key={`category-${key}`}>
                                                    <Link
                                                        to={`/blog?category=${item.id}`}
                                                    >
                                                        {item.label}
                                                    </Link>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    <div className="widget widget_recent_entries">
                                        <h3 className="widget-title">
                                            Les derniers postes
                                        </h3>
                                        <div className="bar"></div>

                                        <ul>
                                            {lastArticles.map((item, key) => (
                                                <li key={`article-${key}`}>
                                                    <Link to="#">
                                                        <img
                                                            src={`${process.env.REACT_APP_API_URL}${item.image.url}`}
                                                            alt="blog"
                                                        />
                                                    </Link>

                                                    <h5>
                                                        <Link to="#">
                                                            {item.title}
                                                        </Link>
                                                    </h5>
                                                    <p className="date">
                                                        {formatDate(
                                                            item.created_at
                                                        )}
                                                    </p>
                                                </li>
                                            ))}
                                        </ul>
                                    </div>

                                    {article && article.tags ? (
                                        <div className="widget widget_tag_cloud">
                                            <h3 className="widget-title">
                                                Tags
                                            </h3>
                                            <div className="bar"></div>

                                            <div className="tagcloud">
                                                {article.tags.map(
                                                    (item, key) => (
                                                        <Link
                                                            key={`tag-${key}`}
                                                        >
                                                            {item.label}
                                                        </Link>
                                                    )
                                                )}
                                            </div>
                                        </div>
                                    ) : (
                                        <div></div>
                                    )}

                                    {/* <div className="widget widget_archive">
                                        <h3 className="widget-title">
                                            Archives
                                        </h3>
                                        <div className="bar"></div>

                                        <ul>
                                            <li>
                                                <Link to="#">
                                                    December 2018
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">January 2019</Link>
                                            </li>
                                            <li>
                                                <Link to="#">
                                                    February 2019
                                                </Link>
                                            </li>
                                            <li>
                                                <Link to="#">March 2019</Link>
                                            </li>
                                        </ul>
                                    </div> */}
                                </div>
                            </div>
                        </article>
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}

export default withRouter(BlogDetails)
