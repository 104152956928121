import React from 'react'
import PropTypes from 'prop-types'
var $ = require('jquery')

const Question = ({ onChange, question, type, options }) => (
    <div>
        <h3 dangerouslySetInnerHTML={{ __html: question }} />
        {getForm(type, options, onChange)}
    </div>
)

const getForm = (type, options = [], onChange = () => {}) => {
    switch (type) {
        case 'question':
            return (
                <div className="input-container">
                    <input
                        onChange={e => onChange(e)}
                        className="effect-3"
                        type="text"
                        placeholder="Tapez votre réponse ici"
                    />
                    <span className="focus-border"></span>
                </div>
            )
        case 'question-text':
            return (
                <div>
                    <div className="input-container">
                        <textarea
                            onChange={e => onChange(e)}
                            className="text-area"
                            placeholder="Tapez votre réponse ici"
                        />
                        <span className="focus-border"></span>
                    </div>
                    <hr />
                    <small>* Alt pour faire un saut de ligne</small>
                </div>
            )
        case 'options':
            return options.map((el, index) => (
                <div key={index}>
                    <input
                        type="radio"
                        id={`radio-${index}`}
                        className="radio-button"
                        onChange={e => onChange(e)}
                        value={el}
                        component="input"
                    />
                    <label htmlFor={`radio-${index}`} className="needs-options">
                        <li>
                            <svg
                                className="SVGInline-svg"
                                width="16"
                                height="13"
                                xmlns="http://www.w3.org/2000/svg"
                            >
                                <path
                                    fillRule="nonzero"
                                    d="M14.293.293l1.414 1.414L5 12.414.293 7.707l1.414-1.414L5 9.586z"
                                ></path>
                            </svg>
                            {el}
                        </li>
                    </label>
                </div>
            ))
        default:
            return <div />
    }
}

if (typeof document !== 'undefined') {
    $(document).on('keydown', 'textarea', function(e) {
        if (e.key === 'Alt') $(this).val($(this).val() + '\n')
        return true
    })
}

Question.propTypes = {
    question: PropTypes.string,
    type: PropTypes.string,
    options: PropTypes.array,
    next: PropTypes.func,
    input: PropTypes.object,
    custom: PropTypes.array
}

Question.defaultProps = {
    question: ``,
    type: ``,
    options: ``,
    next: () => {},
    input: {},
    custom: []
}

export default Question
