import React from 'react'
import { ApiAddContact } from '../../tools/ApiServices'

class Newsletter extends React.Component {
    state = {
        submitted: false,
        email: ''
    }

    onFormSubmit = e => {
        e.preventDefault()
        const { email } = this.state
        let data = {}
        data.email = email
        data.name = 'Annonym'
        data.subject = 'souscrit à la newsletter'
        ApiAddContact(data).then(() => {
            alert('Vous êtes maintenant abonné aux newsletters')
            this.setState({ email: '' })
        })
    }

    render() {
        return (
            <section className="subscribe-area uk-section  uk-subscribe bg-gray">
                <div className="uk-container">
                    <div className="uk-grid uk-grid-match uk-grid-medium uk-child-width-1-2@m uk-child-width-1-1@s">
                        <div className="item">
                            <h3>Abonnez-vous à notre Newsletter</h3>
                        </div>

                        <div className="item">
                            <form
                                className="newsletter-form"
                                onSubmit={this.onFormSubmit}
                            >
                                <input
                                    type="email"
                                    className="uk-input"
                                    placeholder="nom@gmail.com"
                                    value={this.state.email}
                                    onChange={v =>
                                        this.setState({ email: v.target.value })
                                    }
                                />

                                <button
                                    type="submit"
                                    className="uk-button uk-button-default"
                                >
                                    Abonnez-vous
                                </button>
                            </form>
                        </div>
                    </div>
                </div>

                <div className="shape">
                    <img src="/assets/images/footer-shape1.png" alt="shape" />
                </div>
            </section>
        )
    }
}

export default Newsletter
