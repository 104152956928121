import React from 'react'
import { Link } from 'react-router-dom'
import Footer from '../../Common/Footer'
import Requestquote from '../../Common/Requestquote'

class ProjectDetails extends React.Component {
    render() {
        return (
            <>
                {/* <!-- Start Page Title Area --> */}
                <section className="page-title-area uk-page-title">
                    <div className="uk-container">
                        <h1>Achats d’Espaces Publicitaires </h1>
                        <ul>
                            <li>
                                <Link to="/">Communication</Link>
                            </li>
                            <li>Achats d’Espaces Publicitaires</li>
                        </ul>
                    </div>
                </section>
                {/* <!-- End Page Title Area --> */}

                {/* <!-- Start Project Details Area --> */}
                <section className="project-details-area uk-project-details uk-section">
                    <div className="uk-container">
                        <div className="uk-grid uk-flex project-details">
                            <div className="project-details-img uk-width-expand">
                                <img
                                    src="/assets/images/imgcm/MediaManagement.jpg"
                                    alt="project"
                                />
                            </div>

                            <div className="item uk-width-1-5">
                                <div className="project-details-info">
                                    <ul>
                                        {/* <li><span>Customer Name:</span> Steven John</li> */}
                                        <li>
                                            <span>Catégorie:</span>{' '}
                                            Communication
                                        </li>

                                        {/* <li><span>Tags:</span> <Link to="#">Art</Link>, <Link to="#">Design</Link></li> */}
                                        <li>
                                            <span>Partager:</span>

                                            <ul>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-logo"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-twitter"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-logo-1"></i>
                                                    </Link>
                                                </li>
                                                <li>
                                                    <Link to="#">
                                                        <i className="flaticon-linkedin"></i>
                                                    </Link>
                                                </li>
                                            </ul>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>

                        <div className="project-details-desc">
                            <h3>Achats d’Espaces Publicitaires </h3>
                            <h5>
                                Notre force de négociation et notre volume
                                d’achats nous permettent de maîtriser toutes les
                                opportunités médias et astuces d’optimisation
                                pour implémenter des stratégies efficaces, à des
                                budgets réfléchis et exclusifs à nos partenaires
                                clients !
                            </h5>
                            <h5>
                                Affichage, Télévision, Radio, Presse Ecrite et
                                Cinéma ; lorsque nous abordons la planification
                                des médias, nous utilisons une approche
                                transversale qui prend en compte l’analyse des
                                cibles, l'analyse de la concurrence et notre
                                médiathèque historique de campagnes. Nous
                                maximisons le retour sur investissement de tous
                                nos achats publicitaires et nous donnons à
                                chaque campagne une empreinte unique et durable.
                            </h5>

                            {/* <blockquote className="blockquote">
                                <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis noexercit ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt Lorem ipsum dolor sit amet.</p>
                            </blockquote> */}

                            {/* <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis noexercit ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident.</p> */}
                        </div>

                        <Requestquote />
                    </div>
                </section>
                <Footer />
            </>
        )
    }
}

export default ProjectDetails
